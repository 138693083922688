import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './Privateroute';
// RUTAS
import Login from '../pages/login';
import Dashboard from '../pages/Dashboard';
import MapaMonitoreo from '../pages/MapaMonitoreo';
import Peticiones from '../pages/Peticiones';
import Nuevapeticion from '../components/Petiticiones/Nuevo/Nuevapeticion';
import Reportes from '../pages/Reportes';
import Mapa from '../pages/Mapa';
import Zonas from '../pages/Zonas';
import Direcciones from '../pages/Direcciones';
import ReporteDeganancias from '../components/Reportes/Ganancias/Ganancias';
import Ejemplo from '../pages/Ejemplo';
import Calendario from '../pages/Calendario';
import Presupuesto from '../pages/Presupuesto';
import NuevaZona from '../pages/NuevaZona';
import MapaMonitoreoNvo from '../pages/MapaMonitoreoNvo';

const Navigation = () => {

  return (
    <Router>
      <Routes>
        {/* RUTAS PUBLICAS */}
        <Route path="/login" element={<Login />} />


        {/* RUTAS PRIVADAS */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path="/mapaciudad"
          element={
            <ProtectedRoute>
              <MapaMonitoreo />
            </ProtectedRoute>
          }
        />

        <Route
          path="/:Peticiones"
          element={
            <ProtectedRoute>
              <Peticiones />
            </ProtectedRoute>
          }
        />

        <Route
          path="/:Peticiones/:nuevo"
          element={
            <ProtectedRoute>
              <Nuevapeticion />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Reportes"
          element={
            <ProtectedRoute>
              <Reportes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/MapaPrincipal"
          element={
            <ProtectedRoute>
              <Mapa />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ReporteGanancias"
          element={
            <ProtectedRoute>
              <ReporteDeganancias />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ejemplo"
          element={
            <ProtectedRoute>
              <Ejemplo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Zonas"
          element={
            <ProtectedRoute>
              <Zonas />
            </ProtectedRoute>
          }
        />
        <Route
          path="/NuevaZona"
          element={
            <ProtectedRoute>
              <NuevaZona />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Direcciones"
          element={
            <ProtectedRoute>
              <Direcciones />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Calendario"
          element={
            <ProtectedRoute>
              <Calendario />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Presupuesto"
          element={
            <ProtectedRoute>
              <Presupuesto />
            </ProtectedRoute>
          }
        />

        <Route
          path="/MapaMonitor"
          element={
            <ProtectedRoute>
              <MapaMonitoreoNvo />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  )
}
export default Navigation