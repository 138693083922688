import styled from 'styled-components';

const Layoutcontainer = styled.section`
@import url('https://fonts.googleapis.com/css2?family=Amiko:wght@400;600;700&display=swap');
*{
    font-family: 'Amiko', sans-serif;
}
#ContenedorPrincipal{
    background-color: #fff;
    border-radius:10px;
    box-shadow: rgb(106 106 106) -1px 2px 14px -3px;
    margin-top: 20px;
    margin-bottom: 10px;
    overflow-y: auto;
}
#ContenedorPrincipalSinfondo{
    border-radius:10px;
    margin-top: 20px;
    margin-bottom: 10px;
    overflow-y: auto;
}
.ReactModal__Overlay ReactModal__Overlay--after-open{
    z-index: 999 !important;
}
.btnSeleccionartodo button{
    padding:11px 24px 11px 24px; 
    border-radius:7px; 
    color:#fff; 
    font-weight:bold; 
    background:#444971; 
    cursor:pointer;
    border: none;
    filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.35));
    font-size: 15px;
}
.prevTipoPeticion{
background-color: #151B4E;
}
.btnSeleccionartodo button:hover{
    background: #151B4E;
}
.btnAceptar{
    padding-top: 7px;
    display: flex;
    justify-content: center;
    position: sticky;
}
.btnAceptar button {
    background-color: #3BAF29;
    color: #fff;
    border-radius: 7px;
    height: 36px;
    width: 130px;
    border: none;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35));
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    margin: auto;
}
.btnAceptar button:hover {
    background-color: #2E901F;
}
.ContainerCheckAddressButton{
        display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 20px;
    }
    .ContainerCheckAddress p{
        text-align: center;
    color: rgb(102, 103, 112);
    font-weight: 500;
    margin-bottom: 10px;
    }
    .Boton_Aceptar button{
        background-color:#3BAF29;
    color: rgb(255, 255, 255);
    width: 130px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid gray;
    border-radius: 8px;
    font-weight: bold;
    margin-right: 20px;
    filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 4px 4px);
    cursor: pointer;
    margin-top: 10px;
    }
    .Boton_Aceptar button:hover {
    background-color: #2E901F;
}
    .Boton_NuevaPeticion button{
    border-radius: 7px;
    padding-top: 4px;
    border: none;
    filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 4px 4px);
    cursor: pointer;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid gray;
    border-radius: 8px;
    font-weight: bold;
    margin-left: 20px;
}
.Boton_NuevaPeticion button:hover{
    color: rgb(255, 255, 255);
    background: rgb(21, 27, 78);
}
.generalInfoShow{
    background: #c3c3c3;
    border-radius: 6px;
    padding: 10px;
    /* width: 20%; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 20px;
}
.generalInfoShow p{
    color: #535353;
    font-weight: bold;
    margin: 0;
    text-align: center;
}
@media only screen and (min-width: 300px) {
    #ContenedorPrincipal{
    width: 95%;
    margin-left: 2.5%;
    height: 100vh;
}
#ContenedorPrincipalSinfondo{
    width: 95%;
    margin-left: 2.5%;
    height: 100vh;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    #ContenedorPrincipal{
    width: 97%;
    margin-left: 1.5%;
    height: 94%;
}
#ContenedorPrincipalSinfondo{
    width: 97%;
    margin-left: 1.5%;
    height: 94%;
}
}

/* Extra large devices (large laptops and desktops, 1366px and up) */
@media only screen and (min-width: 1366px) {

}
@media only screen and (min-width: 1370px) {

}
`;

const LoginLayout = styled.section`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100vh;
    /* Extra small devices (phones, 600px and down) */
    .wIxkG input:-webkit-autofill, .wIxkG input:-webkit-autofill:hover, .wIxkG input:-webkit-autofill:focus, .wIxkG input:-webkit-autofill:active {
    /* -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s; */
    background: none !important;
    border-color: #fff;
    color: #fff !important;
    padding-left: 10px;
}
.login-principal{
        width:100%;
        width: 100%;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
    }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    /* transition: background-color 5000s ease-in-out 0s; */
  
    border-color: #fff;
    color: #fff !important;
    padding-left: 10px;
}
input:focus-visible {
    outline-offset: none !important;
}
input:focus-visible {
    outline: none !important;
    outline-offset: none !important;
}
input:-webkit-autofill {
    -webkit-text-fill-color: #fff !important;
}
@media only screen and (min-width: 300px) {

    .contendor-login {
    display: flex;
    
}
.cuerpologin {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
}
.contendor-login{
    display: flex;
    justify-content: center;
    width: 90%;
    margin: auto;
    flex: 1;
    height: 100vh;
}
.EscritorioContenedor{
    display:none;
}
.Movilcontenedorlogin{
    width: 100%;
}
.contenedor-datos{
    display: flex;
    flex-direction: column;
}
.cuerpodeformulario{
    width: 90%;
    margin: auto;
}
.cuerpodeformulario h1{
    text-shadow: rgb(70, 73, 71) 0.1em 0.1em 0.2em;
    width: 100%;
    display: flex;
    margin: auto;
    padding: 20px 0px 0px;
    color: var(--blanco);
    font-size: var(--titleLogin)
}
.cuerpodeformulario h2{
    display:flex;
    align-items: center;
    color: var(--blanco);
    font-size: var(--regularmovil)
}
.cuerpodeformulario img{
    height: 2.5vh;
    padding-right: 10px;
}
.cuerpodeformulario input{
    width: 90%;
    display: block;
    margin: auto;
    height: 7%;
    background: transparent;
    border-width: initial;
    border-style: none none solid;
    border-color: initial;
    border-image: initial;
    border-radius: 7px;
    padding-top: 10px;
    padding-left: 0px;
    color: var(--blanco);
    padding-left: 10px;
}
.cuerpodeformulario input:-internal-autofill-selected {
    appearance:none;
    background-image: none !important;
    background-color: transparent !important;
    color: none !important;
    border-bottom-color: var(--blanco)
}
.btnIngresar{
    margin-top: 33px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 33px;
}
.btnIngresar button{
    background-color: var(--verde);
    color: var(--blanco);
    border-radius: 7px;
    padding-top: 4px;
    border: none;
    filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 4px 4px);
    cursor: pointer;
    font-weight: var(--resaltado);
    font-size: var(--regularmovil);
    margin: auto;
    padding: 10px 20px 10px 20px;
}
.btnIngresar button:hover {
    background: var(--verdeHover)
}
.formulario-login{
    margin-top: -10px;
    background: rgb(255 255 255 / 32%);
    margin-top: -10px;
    backdrop-filter: blur(5px);
    border-radius: 0px 0px 20px 20px;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .Movilcontenedorlogin{
        display: none;
    }
    .EscritorioContenedor{
        display:block;
        width: 100%;
    }
    .contendor-login {
        flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.contenedor-datos {
    width: 90%;
}
.cuerpologin{
    flex-direction: row;
}
.formulario-login {
    margin-top: 0px;
    width: 390px;
    display: flex;
    border-radius: 20px 0px 0px 20px;
}
.cuerpodeformulario h1{
    padding: 0px 0px 20px;
}
.cuerpodeformulario {
    width: 100%;
    margin: auto;
    padding-left: 40px;
}
.btnIngresar button{
    margin:0px !important;
}
.btnIngresar{
    justify-content: flex-end;
    padding-right: 15px;
}
.btnIngresar button{
    padding: 10px 20px 10px 20px;
}
}

/* Extra large devices (large laptops and desktops, 1366px and up) */
@media only screen and (min-width: 1366px) {

}
@media only screen and (min-width: 1370px) {

}
`;

const LayoutMonitor = styled.div`

@media only screen and (min-width: 300px) {


    .iLAoaU .grid-container-body {
    display: grid;
    grid-template:
        "headerg header2" 0fr
        "asaide main" 0fr
        "footer footer" 0fr / 0fr 0fr;
    height: 100vh;
    /* transition: all 0.7s ease-out 0s !important; */
}
    .MenuResponsivo{
        position: absolute;
            top: 1px;
            background: linear-gradient(180deg, rgba(21, 27, 78, 0.85) 65.63%, rgba(21, 27, 78, 0.00) 100%), url(${(props) => props.ImagenMonitor}), lightgray 50% / cover no-repeat;
            background-size: cover; /* Ajusta la imagen para cubrir todo el contenedor */
            background-position: center; /* Posición centrada de la imagen */
            width: 100%;
            z-index: 10;
            border-radius: 0px 0px 0px 0px;
            transition: 1s;
    }
    .CrossClose{
        position:relative;
        width: 100%;
       
        height:40px;
    }
    .CrossClose > span:nth-child(1){
        position: absolute;
        background: #fff;
        width: 28px;
        height: 4px;
        border-radius: 4px;
        right: 30px;
        top: 20px;
        transform: rotate(50deg);
        transition: 1s;
    }
    .CrossClose > span:nth-child(2){
        position: absolute;
        background: #fff;
        width: 28px;
        height: 4px;
        border-radius: 4px;
        right: 30px;
        top: 20px;
        transform: rotate(-50deg);
        transition: 1s;
    }
    .header-Grid{
        grid-area: headerg;
        background: #fff;
    }
    .Header-navbar{
        background: ${(props) => props.BodyColor};
        grid-area: header2;
    }
    .Sidebar-Grid{
        display: none;
    }
    .Container-Body-grid{
        grid-area: main;
        background-color: ${(props) => props.BodyColor};
        width: 100%;
    }
    .Footer-grid{
        grid-area: footer;
    }
    .containercopy{
        display: flex;
        justify-content: end;
    }
    .containercopy p {
        margin-top: 10px;
        margin-bottom: 0px;
        font-weight: bold;
        padding-right: 10px;
    }
    .ContainerTituloMenuResponsive{
        font-size: 24px;
        color: white;
        font-weight: bold;
        /* margin-left: 5%; */
        margin: auto;
        width: 83%;
    }
    .ListaMenuResponsivo{
        list-style: none;
    font-weight: bold;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
    }
    .ListaMenuResponsivo li{
        padding-bottom: 10px;
    display: flex;
    align-items: center;
    height: 28px;
    border-radius: 12px;
    padding-left: 14px;
    padding-top: 10px;
    font-weight: lighter;
    }
    .ListaMenuResponsivo li p{
        margin:0px
    }
    .ListaMenuResponsivo li:hover{
        background: #3BAF29
    }
    .containericonlogout{
        background: #ffffff;
        padding: 4px;
        border-radius:10px;
        width: 19px;
        display:flex;
        justify-content: center;
        margin-left: 7px;    
    }
    .containericonlogout svg{
        cursor: pointer;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .grid-container-body{
        display: grid;
        grid-template-columns: 1fr ${(props) => props.activemenu};
        grid-template-areas: 
        'headerg header2'
        'asaide main '
        'footer footer';
        height: 100vh;
        grid-template-rows: 1fr 26fr 1fr;
        /* grid-template-rows: 7% 89% 4%; */
        /* overflow: hidden; */

        /* ANIMACION DE SIDEBAR */
        /* transition: all .7s ease-out !important; */
        transition-timing-function: linear !important;
       
  transition-duration: .7s !important;

    }
    


    .Container-Body-grid{
        grid-area: main;
        background-color: ${(props) => props.BodyColor};
        width: 100%;
        height: 99%;
        overflow: auto;
    }
   
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .MenuResponsivo{
        display:none;
    }
    .Sidebar-Grid{
        display: block;
        grid-area: asaide;
        background-color: #FFFFFF;
        position: relative;
        /* transition: all .7s ease-out; */
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
 
}

/* Extra large devices (large laptops and desktops, 1366px and up) */
@media only screen and (min-width: 1366px) {

}
@media only screen and (min-width: 1370px) {

}
`;

// COMPONENT LAYOUT
const SidebarLayout = styled.section`
height: 100%;
min-height: 100%;
display: flex;
flex-direction: column;
@media only screen and (min-width: 300px) {
    // MENU DESK
    .ContainerFlechaSideMenu{
        position: absolute;
        bottom: 10px;
       right: 10px;
       /* background-color: gray !important; */
       height: 45px;
       width: 40px;
       border-radius: 10px;
       display:flex;
       justify-content:center;
       align-items: center;
       cursor: pointer;
       display: none;
    }
    .ContainerFlechaSideMenu svg{
        transform: rotate(${(props) => props.flechaActive});
        transition: .5s;
    }
    .containermenu{
        list-style: none;
        margin: 0px;
        padding: 0px;
       padding-top: 25%;
       transition: 38.5s;
    }
    .containermenuflex{
        position:relative;
        display:flex;
        align-items: center;
        transition:0.5s;
    }
    // ACTIVE MENU SMALL
    .containermenuflex.active{
        margin-left: 6px;
        background: ${(props) => props.BodyColor};
        border-radius: 10px 0px 0px 10px;
    }
    .containermenuflex.active{
        position: relative;
        flex: 1 1 0%;
        background: ${(props) => props.BodyColor};
        -webkit-box-align: center;
        align-items: center;
        border-radius: 15px 0px 0px 15px;
    }
.containermenuflex::after {
    content: "";
    position: absolute;
    background: transparent;
    width: 13px;
    height: 13px;
    top: -13px;
    right: 0px;
    border-bottom-right-radius: 20px;
    box-shadow: 6px 5px 0 5px ${(props) => props.BodyColor};
    transform: scale(0);
    transform-origin: bottom right;
    transition: 0.5s;
}
.containermenuflex.active::after {
   transform: scale(1)
}
.containermenuflex::before {
    content: "";
    position: absolute;
    background: transparent;
    width: 13px;
    height: 13px;
    bottom: -13px;
    right: 0px;
    border-top-right-radius: 20px;
    box-shadow: rgb(239 240 242) 6px -3px 0px 1px;
    transform: scale(0);
    transform-origin: bottom right;
    transition: 0.5s;
}
.containermenuflex.active::before {
   transform: scale(1)
}

.containermenuflex.active::content {
    color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
}
    .containermenuflex svg{
        margin-left: 10px;
    }
    .indicadormenu{
        position: absolute;
        top: 6px;
        left: 1px;
        height: 80%;
        width: 5px;
        background: black;
        margin: auto;
        transition: 0.5s;
    }
    .item-menu-o{
        position: relative;
        padding-top: 10px;
        padding-bottom: 0px;
        cursor: pointer;
       
    }
    
    .containerRoutesidemenu{
       
        padding-right: 30px;
    }
    .BodyactiveSubmenu{
        list-style: none;
        margin: 0px;
        padding: 0px;
    }
    .BodyactiveSubmenu li{
        margin-top: 10px;
        margin-bottom: 15px;
    }
    .Submenu-Activemenu-Small {
        background: rgb(239, 240, 242);
    position: absolute;
    bottom: -50px;
    width: 139px;
    z-index: 10;
    border-radius: 5px;
    padding-left: 20px;
    right: -135px;
    -webkit-box-shadow: 10px 0 5px -2px #888; 
    box-shadow: 10px 0 10px -2px #888;
}

    // MENU LARGE
    .conatinerindicadorActivemenu{
        
        opacity: 0;
    }
    .conatinerindicadorActivemenu.active{
    position: absolute;
    background: ${(props) => props.BodyColor};
    height: 25px;
    width: 20px;
    right: 0px;
    margin-top: -12px;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 15px 0px 0px 15px;
    opacity:1;
   
    }
.conatinerindicadorActivemenu::after {
    content: "";
    position: absolute;
    background: transparent;
    width: 13px;
    height: 13px;
    top: -13px;
    right: 0px;
    border-bottom-right-radius: 20px;
    box-shadow: 6px 5px 0 5px ${(props) => props.BodyColor};
    transform: scale(0);
    transform-origin: bottom right;
   
}
.conatinerindicadorActivemenu.active::after {
   transform: scale(1)
}
.conatinerindicadorActivemenu::before {
    content: "";
    position: absolute;
    background: transparent;
    width: 13px;
    height: 13px;
    bottom: -13px;
    right: 0px;
    border-top-right-radius: 20px;
    box-shadow: rgb(239 240 242) 6px -3px 0px 1px;
    transform: scale(0);
    transform-origin: bottom right;
   
}
.conatinerindicadorActivemenu.active::before {
   transform: scale(1)
}

.conatinerindicadorActivemenu.active::content {
    color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
}

}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1366px and up) */
@media only screen and (min-width: 1366px) {
}
`;

const HeaderLayout = styled.section`

@media only screen and (min-width: 300px) {
  .ConatinerHeader{
    position: relative;
    display:none;
  }


  .logopkt1HeaderP{
    z-index: 1;
  }
  .logopkt1Header{
    z-index: 1;
  }
  .containerwaveheader{
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
  }
  .containerwaveheader svg{
    transform: scaleX(1) rotate(180deg);
    height: 122px;
        margin-top: -21px;
        margin-left: 0px;
        width: 100%;
    }
    .logopkt1Header{
        margin-top: -6px;
    padding-bottom: 14px;
    }
    .NavbarDesk{
        background: ${(props) => props.BodyColor};
        display: flex;
        justify-content: center;
    }
    .containernavbar{
        width: 97%;
        border-radius: 5px;
        background: ${(props) => props.colormode};
        max-height: 100px;
        margin: auto;
        display:flex;
        flex-direction: row
    }
    .infodepantalla{
        display: flex;
        align-items:center,
    }
    .infodepantalla p {
        font-weight: bold;
        margin-left: 10px;
    }
    .containerNavbarizquierdo{
        display: flex;
        flex-direction: row;
        padding-bottom: 7px;
    }
    .ContainerCiudadSelect{
        display: flex; 
        flex-direction: column;
        padding-left: 20px;
    }
    .ContainerCiudadSelect p{
        font-weight:bold;
        margin-bottom: 0px;
        margin-top: 7px;
    }
    .ContainerCiudadSelect select{
        width: 168px;
            height: 25px;
            border-radius: 7px;
            border-color: #817979;
            margin-top: -2px;
            background-image: url(${(props) => props.selectimage});
            background-repeat: no-repeat;
            background-position: right center;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
    }
    .ContainerCiudadSelect select::-ms-expand {
        display: none; /*Evita que se muestre la flecha por defecto en versiones de IE*/
    }

    .containerNavbarderecho{
        display: flex;
        flex-direction: row;
        align-items:center;
    }
    .conatinerusernav{
        transition: 0.5s;
        display:flex;
        justify-content:center;
        align-items: center;
        padding-right: 15px;
        padding-left: 15px;
    }
    .conatinerusernav img{
        cursor: pointer;
    }
    .containernavmenu{
        position: absolute;
        transition: 0.5s;
        background: #fff;
        display: flex;
        flex-direction: row;
        right: 2px;
        bottom: -85px;
        list-style: none;
        display: inline-block;
        border-radius: 7px;
        z-index: 100;
        box-shadow: 1px salmon;
        -webkit-box-shadow: 2px 6px 23px -12px #918f8f;
        -moz-box-shadow: 2px 6px 23px -12px  #918f8f;
        box-shadow: 2px 6px 23px -12px #918f8f;
        width: 180px;
    }
    .containernavmenu ul{
        list-style: none;
        padding-left: 15px;
    }
    .containerNavbarderecho{
        position: relative
    }
    .containerCerrarsesionnavbar{
        display:flex;
        flex-direction: row;
        align-items: center;
    }
    .containerCerrarsesionnavbar p{
        margin:0px;
        color: #817979;
        font-weight: bold;
        padding-right: 10px;
        cursor: pointer;
    }
    .containericonlogout{
        background: #BFE2BA;
        padding: 5px;
        border-radius:10px;
    }
    .containericonlogout svg{
        cursor: pointer;
    }
    // CAMPANA
    .Containerbellheader{
        position: relative;
    }
    .Containerbellheader-Contador{
        position: absolute;
        background: red;
        padding: 5px;
        border-radius: 50%;
        height: 13px;
        width: 13px;
        text-align:center;
        top:0px;
        right:-10px;
        font-size: 13px;
        font-weight: bold;
        animation: pulse 2s infinite;
    }
    .Nombrepagina{
        font-weight: bold;
        padding-left: 10px;
    }
    .Nombrepagina p {
        margin-bottom: 0px;
        margin-top: 25px;
    }

    // ANIMACION SVG CAMPANA
    #Campana{
        position:relative;
        transition: 0.5s;
        /* animation: Alertanueva 8s infinite; */
        transform-box: fill-box;
        transform-origin: 70% 70%;
    }
    #Campana.Active{
        animation: Alertanueva 8s infinite;
    }
    #Curepocampana{
       
    }
    #Campanita{
        animation: AlertanuevaShica 8s infinite;
        transform-box: fill-box;
        transform-origin: 50% 50%;
    }
    @keyframes pulse {
        0% {
		transform: scale(0.85);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	
	100% {
		transform: scale(0.85);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
    }
    @keyframes Alertanueva {
        0%,90%{
            transform: rotateZ(0);
        }
        5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%{
            transform: rotateZ(25deg);
        }
        10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%{
            transform: rotateZ(-25deg)
        }
    }
    @keyframes AlertanuevaShica {
       
    }
    /* MOVIL */
    .containerHamburger{
        position: relative;
        width: 50px;
        height:30px;
        margin-right: 10px;
        margin-left: 25px;
    }
    .containerHamburger > span:nth-child(1){
        position: absolute;
        background: #fff;
        width: 26px;
        height: 4px;
        border-radius: 4px;
        left: 0px;
    }
    .containerHamburger > span:nth-child(2){
        position: absolute;
        background: #fff;
        width: 35px;
        height: 4px;
        border-radius: 4px;
        left: 0px;
        top: 10px;
    }
    .containerHamburger > span:nth-child(3){
        position: absolute;
        background: #fff;
        width: 26px;
        height: 4px;
        border-radius: 4px;
        left: 0px;
        bottom: 5px;
    }
}

@media only screen and (min-width: 500px) {
    .containerwaveheader svg{
        height: 122px;
        margin-top: -21px;
        margin-left: 0px;
        width: 100%;
    }
}
@media only screen and (min-width: 600px) {

}
@media only screen and (min-width: 768px) {
    .bodynavbar{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: var(--blanco);
        position: relative;
    }
    .containerwaveheader svg{
    transform: scaleX(1) rotate(180deg);
    width: 298px;
    height: 96px;
    margin-top: -16px;
    margin-left: -4px;
    }
    .ContainerLogoHeader{
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .ConatinerHeader{
    position: relative;
    display:flex;
  }
  .containerwaveheadersmall{
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
    }
  .containerwaveheadersmall svg{
        transform: scaleX(1) rotate(180deg);
        width: 275px ;
        height: 89px ;
        margin-top: -15px ;
        margin-left: -54px ;
    }
}
@media only screen and (min-width: 992px) {
 
}
@media only screen and (max-width: 1366px) {

}
@media only screen and (min-width: 1370px) {

}

/* Small devices (portrait tablets and large phones, 600px and up) */

/* Medium devices (landscape tablets, 768px and up) */

/* Large devices (laptops/desktops, 992px and up) */


/* Extra large devices (large laptops and desktops, 1366px and up) */

`;
// PAGINAS 
const DashboardLayout = styled.section`
    display: flex;

    //  RESPONSIVE
@media only screen and (min-width: 300px) {
    .ContainerDashboard{
        display: flex;
        width: 96%;
        margin: auto;
        height: 100vh;
       max-height: 84vh;
       position:relative;
       overflow-y: scroll;
       padding-right: 5%;
    padding-left: 2%;
    /*Firefox*/
  scrollbar-color: #C5C5C9 transparent;
  scrollbar-width: thin;
  scrollbar-gutter: thin;
    }
    .BodyDashboard{
        display:flex;
        flex-direction: column;
        width: 100%;
    }
    .InicioIzquierdo{
        flex: 5;
    }
    .Inicioderecho{
        flex:2;
        padding-right:5px;
        padding-left: 5px;
    }

    // CARD BIENVENIDA
    .Container-BodyCardBienvenido{
        display: flex;
        margin-top: 20px;
        border-radius: 15px;
        background: ${(props) => props.AzulFuerte};
        transition: 0.5s;
        position: relative;
        width: 100%;
        filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.35));
        /* box-shadow: 3px 3px 0 3px ${(props) => props.BodyColor}; */
    }
    .ContenedorNombreAvatar{
        flex: 1;
        height: 15vh;
        color: #333333;
        margin-left: 5%;
        
    }
    .ContenedorNombreAvatar h2{
        margin-bottom:0px;
        color:#fff;
        font-weight:bold
    }
    .ContenedorNombreAvatar h3{
        margin-top:5px;
        color:#fff;
        font-weight: 600;
        max-width: 60%;
        font-size: 18px;
    }
    .ContenedorImganeAvatar img{
        z-index: 1;
        position: absolute;
        right: 10px;
        bottom: 4px;
        width: 106px;
    }
    .SeparadorAvatar{
        display: flex;
        align-items: center;
    }
    .containermonitormapDASH{
        display:flex;
        flex:1;
        flex-direction: row;
        position: relative !important;
        width: 100%;
        margin: auto;
        margin-top: 10px;
        border-radius: 10px;
       /* border: 2px solid #C5C5C9; */
       height: 80vh;
       overflow: hidden;
    }
    .containermonitormapDASH{
        height: 300px !important
    }
    .SeparadorAvatar p{
        font-weight: bold;
        font-size: var(--titlemovil);
        margin-right: 10px;
        color: #333333;
    }
    .MapaPais{
        cursor: pointer;
        color:#3BAF29 !important;
    }
    .MapaPais:hover{
        color: #2E901F;
    }
    .SeparadorAvatar-Reco{
        font-size:var(--titlemovil);
        color:var(--azul);
    }
    // CARD RECOLECTORES
    .ContainerRecoDash{
        width: 100%;
        margin: auto;
        overflow-x: hidden;
        
    }
    .containerCardRecolectoresDash{
        background: ${(props) => props.Azulbajo};
        border-radius: 15px 15px 50px 15px;
        padding-bottom: 30px;
        padding-right: 4px;
        padding-left: 4px;
        margin-top: 10px;
        max-height: 60vh;
        overflow-y: scroll;
        // TEMPORAL
        display: none;
        
    }
    .ContainerCardRecolectores-Titulo{
        text-align: center;
        padding-top:3px;
    }
    .CardRecolectores{
        display:flex;
        background: white;
        border-radius: 15px;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 10px;
       
    }
    .conatinerimagencardrecos{
        flex: 1;
        display: flex;
        justify-content: center;
    }
    .containerInfoCardReco-nombre{
        margin-top: 15px;
        margin-bottom:0px;
    }
    .containerInfoCardReco-Peticion{
        margin-top: 0px;
        margin-left: 15px;
    }
    .ContainerInfoCardRight{
        display:flex;
        flex-direction: column;
    }
    .ContainerInfoCardRight-Flex{
        display:flex;
        flex-direction: row;
    }
    .ContainerInfoCardRight-Estatus{
        display:flex;
        position: relative;
    }
    .ContainerInfoCardRight-Estatus p{
        position: relative;
        padding-right: 5px;
    }
    .ContainerInfoCardRight-Estatus > p > span{
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: red;
        bottom: 6px;
        left: -12px;
    }
    .ContainerInfoCardRight-Zona{
        display:flex;
        width: 100%;
        justify-content: flex-end;
    }
    .ContainerInfoCardRight-Zona p{
        margin: 0px;
        padding-right:10px;
        padding-bottom: 4px;
    }

    // WIDGET
    .ConatinerWidgetDashboard{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 10px;
        grid-auto-rows: minmax(100px,auto);
        width: 98%;
    }
    .ConatainerWidgetPeticiones{
        background: ${(props) => props.GrisFuerte};
        border-radius: 15px;
        display:flex;
        width:100%;
        position: relative;
        height:100px;
        overflow:hidden;
        justify-content: space-between;
    }

 }
 @media only screen and (max-width: 1366px) {
 
}

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {

    .BodyDashboard{
       
        flex-direction: row;
       
    }

    .ContainerDashboard{
       padding: 0px;
    }
 }

// /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {


 }

// /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .SeparadorAvatar p{
        font-weight: bold;
        font-size: var(--title);
        margin-right: 10px;
        color: #333333;
    }
    .SeparadorAvatar-Reco{
        font-size: 28px !important;
        color:var(--azul);
        font-weight: bold;
    }
    .ConatinerWidgetDashboard{
      
      grid-template-columns: repeat(4,1fr);
    
  }
  .Container-BodyCardBienvenido{
        width: 500px;
        margin-top: 3%;
        /* box-shadow: 3px 3px 0 3px ${(props) => props.BodyColor}; */
    }
 }

// /* Extra large devices (large laptops and desktops, 1366px and up) */
 @media only screen and (min-width: 1366px) {
    .ContainerRecoDash{
        width: 100%;
    }
    .ContenedorImganeAvatar img {
    width: 20%;
}

 }
 @media only screen and (min-width: 1370px) {
    .ContainerRecoDash{
        width: 100%;
    }
    .ContenedorImganeAvatar img {
    width: 33%;
}
 }

`
const WidgetDashboarLayout = styled.div`
 @media only screen and (min-width: 300px) {
    .ContainerbkCardWidget{
        width: 33%;
        position: relative;
        background: ${(props) => props.Fondocolor};
        display: flex;
        justify-content:center;
        align-items: center;
    }
    .ContainerbkCardWidget img{
        position: relative;
        z-index: 1;
        width: 100%;
        margin-left: 30%;
    }
    .ContainerbkCardWidget::before {
        position: absolute;
        content: "";
        width: 103px;
        height: 106px;
        bottom: -3px;
        border-radius: 50%;
        right: -34px;
        background:${(props) => props.Fondocolor};
    }
.ContainerWidget-Info{
    display: flex;
    flex-direction: column;
    z-index:1;
    max-width: 175px;
}
.ContainerWidget-Info p{
    text-align: center;
    line-height: 18px;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0px;
    padding-left: 15px;
}
.ContainerDataWidget p{
    margin-top: 9px;
    font-size: 22px !important;
}
}
@media only screen and (max-width: 1366px) {
    .ContainerWidget-Info p{
    text-align: center;
    line-height: 18px;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 0px;
    padding-left: 15px;
}
}

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {

 }

// /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
 
 }

// /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

 }

// /* Extra large devices (large laptops and desktops, 1366px and up) */
 @media only screen and (min-width: 1366px) {
  
 }
 @media only screen and (min-width: 1370px) {

}
`
const MapaMonitorNuevoLayout = styled.section`
 @media only screen and (min-width: 300px) {
   .ConatinerMapaMonitoreo{
        position: relative;
        width: 95;
        margin: auto;
   }
   .widgetWebReco{
    display: none;
   }
   .containermonitormap,
   .containermonitorZonas{
        display:flex;
        flex:1;
        flex-direction: row;
        position: relative !important;
        width: 97%;
        margin: auto;
        margin-top: 10px;
        border-radius: 10px;
       /* border: 2px solid #C5C5C9; */
       height: 80vh;
       overflow: hidden;
    }
    .containermonitorNuevaZona{
        display:flex;
        flex:1;
        flex-direction: row;
        position: relative !important;
        width: 97%;
        margin: auto;
        margin-top: 10px;
        border-radius: 10px;
       /* border: 2px solid #C5C5C9; */
       height: 80vh;
       overflow: hidden;
    }
    .NewMapMonitor,
    .MapZonas{
        width: 100%;
        top:0px;
        left: 0px;
        height: 100%; 
        margin-top: -2px;
        transition: .5s;
}
// MARCADORES RECOLECTORES MAPA NUEVO
.mapboxgl-popup-content{
  padding: 0px 2px 0px;
  height: 36px;
  /* width: 60px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  /* border: 1px solid #fff; */
 border:none;
  top: -4px;
 box-shadow:none;
  background: transparent;
}
.ContainerCardPetiMapa{
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 24px;
}
.InfoPeti{
    padding: 0px 2px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(255, 255, 255);
    background: rgba(255, 255, 255, 0.45);
}
.popup-tipPeti{
    width: 0px;
    height: 0px;
    border-width: 8px;
    border-style: solid;
    border-color: rgb(255, 255, 255) transparent transparent;
    border-image: none;
    margin-top: 3px;
    margin-bottom: 22px;
}
.ContainerTextoPeti{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.ContainerTextoPeti p{
    margin: 0px;
    text-align: center;
    font-size: 11px;
}
.ContainerTextoPeti p:nth-child(2){
    color: #151B4E;
    font-weight: bold;
    font-size: 13px;
}
.ContainerTextoPeti img{
    width: 30px;
}
.mapboxgl-popup-tip{
    display: none;
}
.ContainerCardRecoMapa{
display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.ContainerTextoReco p{
  text-align: center;
  font-weight: bold;
  color: #fff;
  text-shadow: black 0px 0px 2px;
}
.RecoAct{
    padding: 0px 2px 0px;
  height: 36px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 2px solid #2E901F;
  background: rgba(59, 175, 41, 0.45);
}
.RecoRec{
    padding: 0px 2px 0px;
  height: 36px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 2px solid #FFB531;
  background: rgba(255, 181, 49, 0.47);
}
.RecoDoc{
    padding: 0px 2px 0px;
  height: 36px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 2px solid #35C1ED;
  background: #35C1ED7A;
}
.RecoInc{
    padding: 0px 2px 0px;
  height: 36px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 2px solid #B40000;
  background: #B4000075;
}
.popup-tipA{
    width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top-color: #2E901F;
  margin-top: 3px;
}
.popup-tipR{
    width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top-color: #FFB531;
  margin-top: 3px;
}
.popup-tipD{
    width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top-color: #35C1ED;
  margin-top: 3px;
}
.popup-tipI{
    width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top-color: #B40000;
  margin-top: 3px;
}
.vehiculoKANGOO{
    background-image: url(${(props) => props.recoKANGOO});
    background-size: cover;
    width: 49px !important;
    height: 30px !important;
    margin-top:-15px;
    cursor: pointer;
    z-index: 2;
}
.vehiculoLEAF{
    background-image: url(${(props) => props.recoLEAF});
    background-size: cover;
    width: 52px !important;
    height: 27px !important;
    margin-top:-15px;
    cursor: pointer;
    z-index: 2;
}
.vehiculoTRANSIT{
    background-image: url(${(props) => props.recoTRANSIT});
    background-size: cover;
    width: 57px !important;
    height: 28px !important;
    margin-top:-15px;
    cursor: pointer;
    z-index: 2;
}
.vehiculoKIRI{
    background-image: url(${(props) => props.recoKIRI});
    background-size: cover;
    width: 57px !important;
    height: 28px !important;
    margin-top:-15px;
    cursor: pointer;
    z-index: 2;
}
.vehiculoJAC{
    background-image: url(${(props) => props.recoJAC});
    background-size: cover;
    width: 66px !important;
    height: 26px !important;
    margin-top:-15px;
    cursor: pointer;
    z-index: 2;
}
.RecolectorD{
    background-image: url(${(props) => props.recoD});
    background-size: cover;
    width: 65px !important;
    height: 65px !important;
    margin-top:-15px;
    cursor: pointer;
    z-index: 2;
}
.RecolectorR{
    background-image: url(${(props) => props.recoR});
    background-size: cover;
    width: 65px !important;
    height: 65px !important;
    margin-top:-15px;
    cursor: pointer;
    z-index: 2;
}
.RecolectorI{
    background-image: url(${(props) => props.recoI});
    background-size: cover;
    width: 65px !important;
    height: 65px !important;
    margin-top:-15px;
    cursor: pointer;
    z-index: 2;
} 
// MARCADORES PETICIONES MAPA NUEVO
.containerIdentReco{
    width: 20px;
    height: 20px;
    margin-top: 6px;
}
.containerIdentReco p{
    margin: 0px;
    padding-top: 1px;
    font-size: 10.5px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    text-shadow: 0px 0px 2px black;
}
.MarcadorPetA{
    /* background-image: url(https://web.pktuno.mx/PKT1/uploads/origen/Sucursal.png); */
    background-image: url(${(props) => props.imgpetition});
    background-size: cover;
    width:43px;
    height: 42px;
    border-radius:50%;
    cursor: pointer;
    z-index:3;
    display: flex;
    justify-content: center;
}
.MarcadorPetP {
    /* background-image: url(https://web.pktuno.mx/PKT1/uploads/origen/Sucursal.png); */
    background-image: url(${(props) => props.imgpetitionP});
    background-size: cover;
    width:43px;
    height: 42px;
    border-radius:50%;
    cursor: pointer;
    z-index:3;
    display: flex;
    justify-content: center;
}
.MarcadorPetR {
    /* background-image: url(https://web.pktuno.mx/PKT1/uploads/origen/Sucursal.png); */
    background-image: url(${(props) => props.imgpetitionR});
    background-size: cover;
    width:43px;
    height: 42px;
    border-radius:50%;
    cursor: pointer;
    z-index:3;
    display: flex;
    justify-content: center;
}
.MarcadorPetD {
    /* background-image: url(https://web.pktuno.mx/PKT1/uploads/origen/Sucursal.png); */
    background-image: url(${(props) => props.imgpetitionD});
    background-size: cover;
    width:43px;
    height: 42px;
    border-radius:50%;
    cursor: pointer;
    z-index:3;
    display: flex;
    justify-content: center;
}
// MARCADORES RECOLECTORES MAPA VIEJO
.RecoiconoMapa{
    background-image: url(${(props) => props.imgcar});
    background-size: cover;
    width: 65px !important;
    height: 65px !important;
    margin-top:-15px;
    cursor: pointer;
    z-index: 2;
}
.SucursalesIconMapa{
    background-image: url(${(props) => props.SucrusalIcon});
    background-size: cover;
    width: 38px !important;
    height: 28px !important;
    margin-top:-15px;
    cursor: pointer;
}
.RecoiconoMapaD{
    background-image: url(${(props) => props.imgcarD});
    background-size: cover;
    width: 65px !important;
    height: 65px !important;
    margin-top:-15px;
    cursor: pointer;
    z-index: 2;
}
.RecoiconoMapaR{
    background-image: url(${(props) => props.imgcarR});
    background-size: cover;
    width: 65px !important;
    height: 65px !important;
    margin-top:-15px;
    cursor: pointer;
    z-index: 2;
}
.RecoiconoMapaI{
    background-image: url(${(props) => props.imgcarI});
    background-size: cover;
    width: 65px !important;
    height: 65px !important;
    margin-top:-15px;
    cursor: pointer;
    z-index: 2;
}
//MAPA PRINCIPAL
.contenedorInfoEstatus{
    display: none;
}
.contenedorInfoEstatusMovil{
    width: 15%;
    height: 97%;
    position: absolute;
    top: 16px;
    right: 25px;
    /* background: pink; */
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: end;
    font-weight: bold;
    padding-right: 7px;
}
.estatusRecoCiudad{
    height: 28px;
    width: 95%;
    background: #ffffff8e;
    border-radius: 20px;
    margin-top: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35));
    /* box-shadow: rgba(0, 0, 0, 0.397) -1px 2px 14px; */
}

.contenedorCiudadEstatus p{
    margin: 0px;
    color: #151B4E;
    font-size: 15px;
}
.contenedorEstatusRecolectores{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    width: 60%;
}
.contenedorEstatusRecolectores span{
    height: 20px;
    width: 21px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7px;
    color: #fff;
    font-size: 14px;
}

// PETICIONES 
.MarcadorPeticion {
    /* background-image: url(https://web.pktuno.mx/PKT1/uploads/origen/Sucursal.png); */
    background-image: url(${(props) => props.imgpetition});
    background-size: cover;
    width:40px;
    height: 39px;
    border-radius:50%;
    cursor: pointer;
    z-index:3;
}
.MarcadorPeticionP {
    /* background-image: url(https://web.pktuno.mx/PKT1/uploads/origen/Sucursal.png); */
    background-image: url(${(props) => props.imgpetitionP});
    background-size: cover;
    width:40px;
    height: 39px;
    border-radius:50%;
    cursor: pointer;
    z-index:3;
}
.MarcadorPeticionR {
    /* background-image: url(https://web.pktuno.mx/PKT1/uploads/origen/Sucursal.png); */
    background-image: url(${(props) => props.imgpetitionR});
    background-size: cover;
    width:40px;
    height: 39px;
    border-radius:50%;
    cursor: pointer;
    z-index:3;
}
.MarcadorPeticionD {
    /* background-image: url(https://web.pktuno.mx/PKT1/uploads/origen/Sucursal.png); */
    background-image: url(${(props) => props.imgpetitionD});
    background-size: cover;
    width:40px;
    height: 39px;
    border-radius:50%;
    cursor: pointer;
    z-index:3;
}
.ContainerMapaCiudadReco,
.ContainerMapaZonas{
    transition: 0.7s;
    flex-direction: column;
    height: 100vh;
    /* overflow-y: scroll; */
    padding-right: 20px;
    padding-left: 20px;
}
.ContainerRecoMonitoreoCiudad{
    padding-bottom: 30px;
    
}


// WIDGET RECOLECTORES MAPA NUEVO
.BurbujaRecoNvo{
    border-radius: 50%;
    height: 60px;
    width: 60px;
  display: block;
  justify-content: center;
  align-content: center;
  align-items: center;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.20));
  margin: auto;
}
.BurbujaRecoNvo p{
    text-align: center;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    margin: 0px;
    font-size: 21px;
    text-shadow: black 0px 0px 3px;
}
.recoName{
    text-align: center;
    margin-top:5px;
    background:#6a6a6a;
    border-radius:10px;
    color: white;
    font-weight:bold;
    padding-left: 5px;
    padding-right: 5px;
}
// WIDGET RECOLECTORES MAPA ANTERIOR
.BurbujaReco{
    border-radius: 50%;
    height: 60px;
    width: 60px;
}
.BurbujaReco img{
    width: 100%;
}
.BurbujaReco p{
    text-align: center;
    margin-top:0px;
    background:#6a6a6a;
    border-radius:10px;
    color: white;
    font-weight:bold;
}
.ContainerDetallesRECO{
    background: #ffffffb7;
    margin-top: 10px;
    border-radius: 5px;
    overflow-y: scroll;
    max-height: 74vh;
    width: 100%;
    transition: all 1s ease 0s;
    z-index: 3;
}

.conatinerFelx-Header-Reco{
 
}
.conatinerFelx-Header-Reco span{
    background:red;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin-top: -10px;
}
.NombrerecoDetalles{
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 10px;
    text-align: center;
    color: #151b4e;
}
.ContianerTitleRecoDetalle  > h3{
    color:#333333;
    font-weight:bold;
    font-size: 15px;
    display: flex;
  align-content: center;
  align-items: v;
  align-items: center;
  justify-content: center;

}
.ContainerDetallesRecos{
    width: 92%;
    margin: auto
}
.ConatinerTotalPeticiones-DetallesReco{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -15px;
    flex-direction: column-reverse;
}
.ConatinerTotalPeticiones-DetallesReco p {
    color: #333333;
    font-weight: bold;
    font-size: 20px;
    margin: 0px;
}
.ContainerTotal-Total-DetallesReco{
    background: #6a6a6a;
    padding: 8px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
}
.ContainerWidgetRecoDetalle{
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.397) -1px 2px 14px;
    border-radius: 10px;
    height: 130px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    margin-top: 7px;
}
.BodyContainerWidgetRecoDetalle{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.ContainerOrigneWidgetRecoDetalle{
    width: 60px;
    margin-right: 10px;
}
.ContainerOrigneWidgetRecoDetalle img{
    width: 100%;
    margin-top: 15px;
}
.ContainerEstatusProgressbar{
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0px;
    padding: 0px;
}
.AceptedProgressBar{
    background: #3BAF29;
    width: 40px;
    height: 15px;
    margin-right: 3px;
    border-radius: 10px 0px 0px 10px;
}
.RecoProgressBar{
    background: #FFB531;
    width: 40px;
    margin-right: 3px;
}
.DocuProgressBar{
    background: #35C1ED;
    width: 40px;
    border-radius: 0px 10px 10px 0px;
}
.TextInfoWidgetRecoDetalle p{
    margin: 0px;
    font-weight: bold;
    color: #333333;
}
.ContainerProgressbar p{
    font-weight: bold;
    color: #333333;
}
.ContainerProgressbar{
    flex: 1
}
.ContainerProgressbar p {
    margin-top: 10px;
    margin-bottom: 3px
}
.continerTiempo p{
    margin: 0px;
    margin-top: 37px;
    font-weight: bold;
    color: #333333;
    font-size: 14px;
}
.ContainerInfoFooterRecodetalle{
    display:flex;
    width: 100%;
    justify-content: space-around;
    align-items: center
}
.Container-PeticionCurso{
    position: relative;
}
.Titulo-PeticionCurso{
    font-weight: bold;
    margin-bottom: 1px;
    margin-top: 7px;
    color: #333333;
}
.conatinerFlex-PeticionCurso{
    display: flex; 
    flex-direction: row;
}
.containerfristFlex{
    flex: 1;
}
.SelecionarPeticiones{
    display:flex;
    justify-content: center;
    flex-direction: row;
    width: 90%;
    box-shadow: rgb(106, 106, 106) -1px 2px 14px;
    background: #fff;
    border-radius: 10px;
    margin: auto;
    margin-left: 6%;
    margin-top: 20px;
}
.SelecionarPeticiones > div:nth-child(1){
   position: relative;
}
.SelecionarPeticiones > div:nth-child(1)::before{
   background: #888888;
   position: absolute;
   content: "";
   width: 1px;
   height: 100%;
   top: 0px;
   right: 8px;
}
.SelecionarPeticiones > .Active{
    width: 50% !important;
    position: relative;
}
.SelecionarPeticiones > .Active::after{
    background: rgb(59, 175, 41);
    position: absolute;
    content: "";
    height: 4px;
    width: 40%;
    bottom: -1px;
    border-radius: 0px;
}
.BotonSelecionarPetciones-Detalles{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 0px;
    border-radius: 10px;
    font-weight: bold;
    color: #000;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    
    /* border: 1.5px solid gray; */
}
.BotonSelecionarPetciones-Detalles p{
    margin: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
}
.TimelinePeticiones{
    list-style: none;
    margin: 0px;
    /* padding-left: 20px; */
}
.conatineridicador-Timlinepeticiones{
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
}
.IndicadorStep-Timlinepeticiones{
    position: absolute;
    content: "";
    bottom: 18px;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    left: -21px;

}
/* .Indicadorlinea-Timlinepeticiones{
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    background: red;
} */
/*TABLA PETICIONES PENDIENTES */
.TablaPeticionesPendientes{
    border-radius: 0px 10px 10px 0px;
    overflow-y: hidden;
    /* max-height: 24vh; */
    height: 44vh;
    width: 98%;
    -webkit-transition: .5s;
    transition: .3s;
    background-color: transparent;
    margin: auto;
    margin-left: 12px;
}
.PeticionPendiente{
    text-align: center;
    margin-top: 0px;
    background: #fb8000;
    border-radius: 8px;
    width: 247px;
    height: 40px;
    padding: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: pulse-animation 2s infinite;
    -webkit-transition: .10s;
    transition: .5s;
    z-index: 10;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px #5b5f8372;
  }
  100% {
    box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
  }
}
.PeticionPendiente:hover{
    background: #d76d00;
}
.contenedorPeticionPendiente{
    position: absolute; 
    width: 100%; 
    display: flex; 
    justify-content: center;
    bottom: 12px;
  }
  .cuerpoPendientes{
    max-height: 34vh;
  }
  .contenedorPeticionPendienteActiv{
    position: absolute; 
    width: 100%; 
    display: flex; 
    justify-content: center;
    top: 100px;
  }
.PeticionPendiente p{
    color: #fff;
    font-weight: bold;
    font-size: 18px;
}
.contenedorPeticionPendienteActiv{
    top: 36%;
  }
.flechaPendientes{
    width: 10%;
    padding-right: 10px;
}
.flechaPendientes img{
    width: 70%;
}
.contenedorPaginador{
    display: none;
}
/* ESTILOS ZONAS */
.iconNuevaZona{
    position: absolute;
    top: 12px;
    right: 292px;
    cursor: pointer;
    /* width: 22%; */
    /* height: 100% */
}
.contenedorInfoNuevaZona{
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    width: 22%;
    z-index: 20;
    height: 100%
}
.containerInfoZonas{
    width: 40%;
}
.contenedorNuevaZona{
    background: #ffffffb7;
    /* margin-top: 10px; */
    border-radius: 0px 10px 10px 0px;
    max-height: 80vh;
    /* width: 100%; */
    /* transition: all 1s ease 0s; */
    position: absolute;
    right: 3%;
    height: 77vh;
    top: 1px;
    width: 100%;
    display: flex;
    /* justify-content: center; */
    align-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
}
.contenedorNuevaZona {
    text-align: center;
    font-weight: bold;
    color: #151b4e;
}
.tituloZona{
    color: #ffff;
    background-color: #151B4E;
    width: 70%;
    height: 32px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contenedorRecolectoresNuevaZona,
.contenedorCambioVista,
.contenedorGuardarZona{
    border: none;
    width: 90%;
    margin-top: 14px;
    box-shadow: rgba(0, 0, 0, 0.397) -1px 2px 14px;
    background: #fff;
    border-radius: 8px;
    /* margin-left: 3%; */
    padding-bottom: 20px;
}
.contenedorCambioVista{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-bottom: 0px;
    height: 37px;
}
.vistaNuevaEditar,
.vistaZonas{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: rgb(51, 51, 51);
    padding-left: 5px;
    padding-right: 5px;
}
.contenedorCambioVista p{
    margin: 6px;
} 
.vistaNuevaEditar hr,
.vistaZonas hr{
    background: rgb(59, 175, 41);
    height: 4px;
    width: 50%;
    margin: 0px;
    border: none;
}
.contenedorRecolectoresNuevaZona{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    padding-top:10px;
}
.contenedorSeleccionarRecoZona{
    display: flex;
    align-items: start;
    flex-direction: column;
    width: 85%;
}
.containerIndicadorZona{
    height: 22px;
    /* width: 90%; */
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    /* padding-right: 20px;
    padding-left: 20px; */
    padding-right: 5px;
    padding-left: 5px;
}
/* .recolectorZona{
    height: 22px;
    background-color: #828282;
    border-radius: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    padding-right: 30px;
    padding-left: 30px;
} */
.recolectorZona{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    width: 90%;
    margin: auto;
    justify-content: center;
    height: 39px;
}
.asignar img{
cursor: pointer;
width: 40px;
}
.nombreRecolector{
    width: 78%;
    color: #333333;
    font-weight: bold;
}/* .imgrecolector{
    width: 38px;
    margin-right: 10px;
}
.imgrecolector img{
    width: 100%;
} */
.recolectorZona p{
    color: rgb(51, 51, 51);
    text-align: justify !important;
    font-size: 14px;
    /* margin-left: 15px; */
}
.contenedorSeleccionarRecoZona p:hover{
color: #5c5f85;
}
/* .ContainerMapaZonas{
    transition: 0.7s;
    height: 95vh;
    padding-right: 20px;
    padding-left: 20px;
    width: 60%;
} */
 /* .containermonitorZonas{
        display:flex;
        flex:1;
        flex-direction: row;
        position: relative !important;
        width: 97%;
        margin: auto;
        margin-top: 10px;
        border-radius: 10px;
       height: 77vh;
       overflow: hidden;
    } */
}
@media only screen and (max-width: 1366px) { 
 
}

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .widgetMovilReco{
        display: none;
    }
    .widgetWebReco{
        display:block;
    }
    .ContainerMapaCiudadReco,
    .ContainerMapaZonas{
   display:flex;
    flex-direction: row;
    max-height: 80vh;
  
}
.ContainerDetallesRECO{
    width: 22%;
}

//ZONAS
/* .contenedorNuevaZona{
    right: 1%;

} */
 }

// /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
   //MAPA PRINCIPAL
   .contenedorCiudadEstatus{
    width: 25%;
}
.contenedorInfoEstatusMovil{
    display: none;
}
.contenedorInfoEstatus{
    width: 21%;
    height: 97%;
    position: absolute;
    top: 16px;
    right: 25px;
    /* background: pink; */
    z-index: 50;
    display: flex;
    flex-direction: column;
    align-items: end;
    font-weight: bold;
}
 }

// /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .contenedorInfoEstatus{
    width: 14%;
}
 }

// /* Extra large devices (large laptops and desktops, 1366px and up) */
 @media only screen and (min-width: 1367px) {
    .containermonitormap,
    .containermonitorZonas{
       height: 85vh;
    }
   .contenedorPeticionPendienteActiv{
    top: 65%;
  }
  .TablaPeticionesPendientes{
    /* max-height: 24vh; */
    height: 24vh;
    width: 99%;
    margin-left: 8px;
}
.cuerpoPendientes{
    max-height: 34vh;
  }
  .contenedorInfoEstatus{
    width: 10%;
}
 }
 @media only screen and (min-width: 1370px) {
    .ContainerDetallesRECO{
    max-height: 80vh;
}
   .contenedorInfoNuevaZona{
    width: 16%;
   }
   //ZONAS
.contenedorNuevaZona{
    right: 1%;
}
.iconNuevaZona{
    right: 300px;
}
}
 @keyframes pulserec {
        0% {
		transform: scale(0.85);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	
	100% {
		transform: scale(0.85);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
    }
`
const MapaMonitorLayout = styled.section`
 @media only screen and (min-width: 300px) {
   .ConatinerMapaMonitoreo{
        position: relative;
        width: 95;
        margin: auto;
   }
   .containermonitormap,
   .containermonitorZonas{
        display:flex;
        flex:1;
        flex-direction: row;
        position: relative !important;
        width: 97%;
        margin: auto;
        margin-top: 10px;
        border-radius: 10px;
       /* border: 2px solid #C5C5C9; */
       height: 80vh;
       overflow: hidden;
    }
    .containermonitorNuevaZona{
        display:flex;
        flex:1;
        flex-direction: row;
        position: relative !important;
        width: 97%;
        margin: auto;
        margin-top: 10px;
        border-radius: 10px;
       /* border: 2px solid #C5C5C9; */
       height: 80vh;
       overflow: hidden;
    }
    .NewMapMonitor,
    .MapZonas{
        width: 100%;
        top:0px;
        left: 0px;
        height: 100%; 
        margin-top: -2px;
        transition: .5s;
}
.ContainerCardRecoMapa{
display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.ContainerTextoReco p{
  text-align: center;
  font-weight: bold;
}
.ContainerImagenRecoMapaPETICION img{
  width: 50%;
}
.ContainerImagenRecoMapa img{
  width: 50%;
}
.mapboxgl-popup-content{
  position: relative;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0,0,0,.1);
  padding: 10px 10px 15px;
  pointer-events: auto;
}
// MARCADORES PETICIONES MAPA NUEVO
.containerIdentReco{
    width: 20px;
    height: 20px;
    margin-top: 6px;
}
.containerIdentReco p{
    margin: 0px;
    padding-top: 1px;
    font-size: 10.5px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    text-shadow: 0px 0px 2px black;
}
.MarcadorPetA{
    /* background-image: url(https://web.pktuno.mx/PKT1/uploads/origen/Sucursal.png); */
    background-image: url(${(props) => props.imgpetition});
    background-size: cover;
    width:43px;
    height: 42px;
    border-radius:50%;
    cursor: pointer;
    z-index:3;
    display: flex;
    justify-content: center;
}
.MarcadorPetP {
    /* background-image: url(https://web.pktuno.mx/PKT1/uploads/origen/Sucursal.png); */
    background-image: url(${(props) => props.imgpetitionP});
    background-size: cover;
    width:43px;
    height: 42px;
    border-radius:50%;
    cursor: pointer;
    z-index:3;
    display: flex;
    justify-content: center;
}
.MarcadorPetR {
    /* background-image: url(https://web.pktuno.mx/PKT1/uploads/origen/Sucursal.png); */
    background-image: url(${(props) => props.imgpetitionR});
    background-size: cover;
    width:43px;
    height: 42px;
    border-radius:50%;
    cursor: pointer;
    z-index:3;
    display: flex;
    justify-content: center;
}
.MarcadorPetD {
    /* background-image: url(https://web.pktuno.mx/PKT1/uploads/origen/Sucursal.png); */
    background-image: url(${(props) => props.imgpetitionD});
    background-size: cover;
    width:43px;
    height: 42px;
    border-radius:50%;
    cursor: pointer;
    z-index:3;
    display: flex;
    justify-content: center;
}
// MARCADORES RECOLECTORES MAPA VIEJO
.RecoiconoMapa{
    background-image: url(${(props) => props.imgcar});
    background-size: cover;
    width: 65px !important;
    height: 65px !important;
    margin-top:-15px;
    cursor: pointer;
    z-index: 2;
}
.SucursalesIconMapa{
    background-image: url(${(props) => props.SucrusalIcon});
    background-size: cover;
    width: 38px !important;
    height: 28px !important;
    margin-top:-15px;
    cursor: pointer;
}
.RecoiconoMapaD{
    background-image: url(${(props) => props.imgcarD});
    background-size: cover;
    width: 65px !important;
    height: 65px !important;
    margin-top:-15px;
    cursor: pointer;
    z-index: 2;
}
.RecoiconoMapaR{
    background-image: url(${(props) => props.imgcarR});
    background-size: cover;
    width: 65px !important;
    height: 65px !important;
    margin-top:-15px;
    cursor: pointer;
    z-index: 2;
}
.RecoiconoMapaI{
    background-image: url(${(props) => props.imgcarI});
    background-size: cover;
    width: 65px !important;
    height: 65px !important;
    margin-top:-15px;
    cursor: pointer;
    z-index: 2;
}
//MAPA PRINCIPAL
.contenedorInfoEstatus{
    display: none;
}
.contenedorInfoEstatusMovil{
    width: 15%;
    height: 97%;
    position: absolute;
    top: 16px;
    right: 25px;
    /* background: pink; */
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: end;
    font-weight: bold;
    padding-right: 7px;
}
.estatusRecoCiudad{
    height: 28px;
    width: 95%;
    background: #ffffff8e;
    border-radius: 20px;
    margin-top: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35));
    /* box-shadow: rgba(0, 0, 0, 0.397) -1px 2px 14px; */
}

.contenedorCiudadEstatus p{
    margin: 0px;
    color: #151B4E;
    font-size: 15px;
}
.contenedorEstatusRecolectores{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    width: 60%;
}
.contenedorEstatusRecolectores span{
    height: 20px;
    width: 21px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7px;
    color: #fff;
    font-size: 14px;
}

// PETICIONES 
.MarcadorPeticion {
    /* background-image: url(https://web.pktuno.mx/PKT1/uploads/origen/Sucursal.png); */
    background-image: url(${(props) => props.imgpetition});
    background-size: cover;
    width:40px;
    height: 39px;
    border-radius:50%;
    cursor: pointer;
    z-index:3;
}
.MarcadorPeticionP {
    /* background-image: url(https://web.pktuno.mx/PKT1/uploads/origen/Sucursal.png); */
    background-image: url(${(props) => props.imgpetitionP});
    background-size: cover;
    width:40px;
    height: 39px;
    border-radius:50%;
    cursor: pointer;
    z-index:3;
}
.MarcadorPeticionR {
    /* background-image: url(https://web.pktuno.mx/PKT1/uploads/origen/Sucursal.png); */
    background-image: url(${(props) => props.imgpetitionR});
    background-size: cover;
    width:40px;
    height: 39px;
    border-radius:50%;
    cursor: pointer;
    z-index:3;
}
.MarcadorPeticionD {
    /* background-image: url(https://web.pktuno.mx/PKT1/uploads/origen/Sucursal.png); */
    background-image: url(${(props) => props.imgpetitionD});
    background-size: cover;
    width:40px;
    height: 39px;
    border-radius:50%;
    cursor: pointer;
    z-index:3;
}
.ContainerMapaCiudadReco,
.ContainerMapaZonas{
    transition: 0.7s;
    flex-direction: column;
    height: 100vh;
    /* overflow-y: scroll; */
    padding-right: 20px;
    padding-left: 20px;
}
.ContainerRecoMonitoreoCiudad{
    padding-bottom: 30px;
    
}
.ContainerRecoMonitoreoCiudadActive{
    margin-right: 9px;
}
.ContainerRecoMonitoreoCiudadActive::before{
    position: absolute;
    content: "";
    width: 0px;
    right: -10px;
    top: 15px;
    height: 2px;
    border-right: 16px solid #ffffffb7;;
    border-top: 18px solid transparent;
    border-bottom: 20px solid transparent;
}
// WIDGET RECOLECTORES MAPA ANTERIOR
.BurbujaReco{
    border-radius: 50%;
    height: 60px;
    width: 60px;
}
.BurbujaReco img{
    width: 100%;
}
.BurbujaReco p{
    text-align: center;
    margin-top:0px;
    background:#6a6a6a;
    border-radius:10px;
    color: white;
    font-weight:bold;
}
.ContainerDetallesRECO{
    background: #ffffffb7;
    margin-top: 10px;
    border-radius: 0px 10px 10px 0px;
    overflow-y: scroll;
    max-height: 74vh;
    width: 100%;
    transition: all 1s ease 0s;
    z-index: 3;
}

.conatinerFelx-Header-Reco{
 
}
.conatinerFelx-Header-Reco span{
    background:red;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin-top: -10px;
}
.NombrerecoDetalles{
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 10px;
    text-align: center;
}

.ContianerTitleRecoDetalle  > h3{
    color:#333333;
    font-weight:bold;
    text-align: center;
    padding-top:5px;
    padding-bottom: 5px;
    margin-top: 9px;
    font-size: 15px;
}
.ContainerDetallesRecos{
    width: 92%;
    margin: auto
}
.ConatinerTotalPeticiones-DetallesReco{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -15px;
}
.ConatinerTotalPeticiones-DetallesReco p {
    color: #333333;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 2px;
    margin-top: 14px;
}
.ConatinerTotalPeticiones-DetallesReco > p {
    margin-right: 14px;
}
.ContainerTotal-Total-DetallesReco{
    background: #6a6a6a;
    padding: 8px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
}
.ContainerWidgetRecoDetalle{
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.397) -1px 2px 14px;
    border-radius: 10px;
    height: 130px;
    padding-left: 10px;
    padding-right: 10px;
}
.BodyContainerWidgetRecoDetalle{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.ContainerOrigneWidgetRecoDetalle{
    width: 60px;
    margin-right: 10px;
}
.ContainerOrigneWidgetRecoDetalle img{
    width: 100%;
    margin-top: 15px;
}
.ContainerEstatusProgressbar{
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0px;
    padding: 0px;
}
.AceptedProgressBar{
    background: #3BAF29;
    width: 40px;
    height: 15px;
    margin-right: 3px;
    border-radius: 10px 0px 0px 10px;
}
.RecoProgressBar{
    background: #FFB531;
    width: 40px;
    margin-right: 3px;
}
.DocuProgressBar{
    background: #35C1ED;
    width: 40px;
    border-radius: 0px 10px 10px 0px;
}
.TextInfoWidgetRecoDetalle p{
    margin: 0px;
    font-weight: bold;
    color: #333333;
}
.ContainerProgressbar p{
    font-weight: bold;
    color: #333333;
}
.ContainerProgressbar{
    flex: 1
}
.ContainerProgressbar p {
    margin-top: 10px;
    margin-bottom: 3px
}
.continerTiempo p{
    margin: 0px;
    margin-top: 37px;
    font-weight: bold;
    color: #333333;
    font-size: 14px;
}
.ContainerInfoFooterRecodetalle{
    display:flex;
    width: 100%;
    justify-content: space-around;
    align-items: center
}
.Container-PeticionCurso{
    position: relative;
}
.Titulo-PeticionCurso{
    font-weight: bold;
    margin-bottom: 1px;
    margin-top: 7px;
    color: #333333;
}
.conatinerFlex-PeticionCurso{
    display: flex; 
    flex-direction: row;
}
.containerfristFlex{
    flex: 1;
}
.SelecionarPeticiones{
    display:flex;
    justify-content: center;
    flex-direction: row;
    width: 90%;
    box-shadow: rgb(106, 106, 106) -1px 2px 14px;
    background: #fff;
    border-radius: 10px;
    margin: auto;
    margin-left: 6%;
    margin-top: 20px;
}
.SelecionarPeticiones > div:nth-child(1){
   position: relative;
}
.SelecionarPeticiones > div:nth-child(1)::before{
   background: #888888;
   position: absolute;
   content: "";
   width: 1px;
   height: 100%;
   top: 0px;
   right: 8px;
}
.SelecionarPeticiones > .Active{
    width: 50% !important;
    position: relative;
}
.SelecionarPeticiones > .Active::after{
    background: rgb(59, 175, 41);
    position: absolute;
    content: "";
    height: 4px;
    width: 40%;
    bottom: -1px;
    border-radius: 0px;
}
.BotonSelecionarPetciones-Detalles{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 0px;
    border-radius: 10px;
    font-weight: bold;
    color: #000;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    
    /* border: 1.5px solid gray; */
}
.BotonSelecionarPetciones-Detalles p{
    margin: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
}
.TimelinePeticiones{
    list-style: none;
    margin: 0px;
    /* padding-left: 20px; */
}
.conatineridicador-Timlinepeticiones{
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
}
.IndicadorStep-Timlinepeticiones{
    position: absolute;
    content: "";
    bottom: 18px;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    left: -21px;

}
/* .Indicadorlinea-Timlinepeticiones{
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    background: red;
} */
/*TABLA PETICIONES PENDIENTES */
.TablaPeticionesPendientes{
    border-radius: 0px 10px 10px 0px;
    overflow-y: hidden;
    /* max-height: 24vh; */
    height: 36vh;
    width: 98%;
    -webkit-transition: .5s;
    transition: .3s;
    background-color: transparent;
    margin: auto;
    margin-left: 12px;
}
.PeticionPendiente{
    text-align: center;
    margin-top: 0px;
    background: #fb8000;
    border-radius: 8px;
    width: 247px;
    height: 40px;
    padding: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: pulse-animation 2s infinite;
    -webkit-transition: .10s;
    transition: .5s;
    z-index: 10;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px #5b5f8372;
  }
  100% {
    box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
  }
}
.PeticionPendiente:hover{
    background: #d76d00;
}
.contenedorPeticionPendiente{
    position: absolute; 
    width: 100%; 
    display: flex; 
    justify-content: center;
    bottom: 12px;
  }
  .cuerpoPendientes{
    max-height: 23vh;
  }
  .contenedorPeticionPendienteActiv{
    position: absolute; 
    width: 100%; 
    display: flex; 
    justify-content: center;
    top: 100px;
  }
.PeticionPendiente p{
    color: #fff;
    font-weight: bold;
    font-size: 18px;
}
.contenedorPeticionPendienteActiv{
    top: 46%;
  }
.flechaPendientes{
    width: 10%;
    padding-right: 10px;
}
.flechaPendientes img{
    width: 70%;
}
.contenedorPaginador{
    display: none;
}
/* ESTILOS ZONAS */
.iconNuevaZona{
    position: absolute;
    top: 12px;
    right: 292px;
    cursor: pointer;
    /* width: 22%; */
    /* height: 100% */
}
.contenedorInfoNuevaZona{
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    width: 22%;
    z-index: 20;
    height: 100%
}
.containerInfoZonas{
    width: 40%;
}
.contenedorNuevaZona{
    background: #ffffffb7;
    /* margin-top: 10px; */
    border-radius: 0px 10px 10px 0px;
    max-height: 80vh;
    /* width: 100%; */
    /* transition: all 1s ease 0s; */
    position: absolute;
    right: 3%;
    height: 77vh;
    top: 1px;
    width: 100%;
    display: flex;
    /* justify-content: center; */
    align-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
}
.contenedorNuevaZona {
    text-align: center;
    font-weight: bold;
    color: #151b4e;
}
.tituloZona{
    color: #ffff;
    background-color: #151B4E;
    width: 70%;
    height: 32px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contenedorRecolectoresNuevaZona,
.contenedorCambioVista,
.contenedorGuardarZona{
    border: none;
    width: 90%;
    margin-top: 14px;
    box-shadow: rgba(0, 0, 0, 0.397) -1px 2px 14px;
    background: #fff;
    border-radius: 8px;
    /* margin-left: 3%; */
    padding-bottom: 20px;
}
.contenedorCambioVista{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-bottom: 0px;
    height: 37px;
}
.vistaNuevaEditar,
.vistaZonas{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: rgb(51, 51, 51);
    padding-left: 5px;
    padding-right: 5px;
}
.contenedorCambioVista p{
    margin: 6px;
} 
.vistaNuevaEditar hr,
.vistaZonas hr{
    background: rgb(59, 175, 41);
    height: 4px;
    width: 50%;
    margin: 0px;
    border: none;
}
.contenedorRecolectoresNuevaZona{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    padding-top:10px;
}
.contenedorSeleccionarRecoZona{
    display: flex;
    align-items: start;
    flex-direction: column;
    width: 85%;
}
.containerIndicadorZona{
    height: 22px;
    /* width: 90%; */
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    /* padding-right: 20px;
    padding-left: 20px; */
    padding-right: 5px;
    padding-left: 5px;
}
/* .recolectorZona{
    height: 22px;
    background-color: #828282;
    border-radius: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    padding-right: 30px;
    padding-left: 30px;
} */
.recolectorZona{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    width: 90%;
    margin: auto;
    justify-content: center;
    height: 39px;
}
.asignar img{
cursor: pointer;
width: 40px;
}
.nombreRecolector{
    width: 78%;
    color: #333333;
    font-weight: bold;
}/* .imgrecolector{
    width: 38px;
    margin-right: 10px;
}
.imgrecolector img{
    width: 100%;
} */
.recolectorZona p{
    color: rgb(51, 51, 51);
    text-align: justify !important;
    font-size: 14px;
    /* margin-left: 15px; */
}
.contenedorSeleccionarRecoZona p:hover{
color: #5c5f85;
}
/* .ContainerMapaZonas{
    transition: 0.7s;
    height: 95vh;
    padding-right: 20px;
    padding-left: 20px;
    width: 60%;
} */
 /* .containermonitorZonas{
        display:flex;
        flex:1;
        flex-direction: row;
        position: relative !important;
        width: 97%;
        margin: auto;
        margin-top: 10px;
        border-radius: 10px;
       height: 77vh;
       overflow: hidden;
    } */
}
@media only screen and (max-width: 1366px) { 
 
}

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .ContainerMapaCiudadReco,
    .ContainerMapaZonas{
   display:flex;
    flex-direction: row;
    max-height: 80vh;
  
}
.ContainerDetallesRECO{
    width: 22%;
}

//ZONAS
/* .contenedorNuevaZona{
    right: 1%;

} */
 }

// /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
   //MAPA PRINCIPAL
   .contenedorCiudadEstatus{
    width: 25%;
}
.contenedorInfoEstatusMovil{
    display: none;
}
.contenedorInfoEstatus{
    width: 21%;
    height: 97%;
    position: absolute;
    top: 16px;
    right: 25px;
    /* background: pink; */
    z-index: 50;
    display: flex;
    flex-direction: column;
    align-items: end;
    font-weight: bold;
}
 }

// /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .contenedorInfoEstatus{
    width: 14%;
}
 }

// /* Extra large devices (large laptops and desktops, 1366px and up) */
 @media only screen and (min-width: 1367px) {
    .containermonitormap,
    .containermonitorZonas{
       height: 85vh;
    }
   .contenedorPeticionPendienteActiv{
    top: 65%;
  }
  .TablaPeticionesPendientes{
    /* max-height: 24vh; */
    height: 24vh;
    width: 99%;
    margin-left: 8px;
}
.cuerpoPendientes{
    max-height: 15vh;
  }
  .contenedorInfoEstatus{
    width: 10%;
}
 }
 @media only screen and (min-width: 1370px) {
    .ContainerDetallesRECO{
    max-height: 80vh;
}
   .contenedorInfoNuevaZona{
    width: 16%;
   }
   //ZONAS
.contenedorNuevaZona{
    right: 1%;
}
.iconNuevaZona{
    right: 300px;
}
}
 @keyframes pulserec {
        0% {
		transform: scale(0.85);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	
	100% {
		transform: scale(0.85);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
    }
`
const PeticionesLayout = styled.section`
 @media only screen and (min-width: 300px) {
 .btnCambioVista{
    background-color: #f0f1f3;
    border: #666770 solid 1px;
    border-radius: 5px;
    height: 37px;
    width: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-right: 30px;
 }
 .btnCambioVista img{
    width: 38px;
    height: 27px;
 }
    /* MODAL CAMBIO RECOLECTOR */
    .ItemCuerpoLista{
  height: 10vh;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #333333;
  cursor: pointer;
}
.ItemCuerpoLista:hover{
background: var(--verdeHover);
color: #fff;
}
    height: 99%;
    /* padding-right: 3%; */
    .ContainerPaginaPeticiones{
        flex: 1;
        width: 95%;
        margin: auto;
        height: 65vh;
        margin-top: 43px;
    }
    .ContainerDrag{
        display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 10px; /* Espacio entre los elementos */
  max-height: 100vh;
  width: 90%;
    margin: auto;
    }
    .card-Drag-Peticiones{
    background: #eff0f29c;
    border-radius: 9px;
    max-height: 100%;
}
.containertitlecard{
    display: flex;
    justify-content: space-between;
    padding-right: 15px;
    padding-left: 15px;
}
.Headertitle-card{
    color: #151b4e;
    font-weight: bold;
    margin-top: 10px;
}
.BodyPetitions{
        overflow-y: scroll;
        overflow-x: hidden;
        height: 50vh;
          max-height: 70vh;
    }
    .BodyPetitions::-webkit-scrollbar {
  width: 8px;
  background-color: transparent; /* Fondo transparente */
}
.BodyPetitions::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}
.BodyPetitions::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.ContainerBodyDRAG{
    height: 30vh;
    transition: 0.5s;
}
.CardSinglePetitions{
    border: 2px solid transparent;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 18px;
    position: relative;
    margin-right: 10px !important;
    margin-left: 10px !important;
    padding-bottom: 0px;
   // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35));
}
.BkBlanco{
    background: #fff;
}
.bkAmarillo{
    background: #fff;
    border: 1px solid #ffb531;
  padding: 20px 20px;
  box-shadow: 0px 0px 10px #ffb531;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    animation: infinite resplandorAnimation 2s;

}
.bkazul{
    background: #fff;
    border: 1px solid #35C1ED;
  padding: 20px 20px;
  box-shadow: 0px 0px 10px #35C1ED;
    animation: infinite resplandorAnimationAzul 2s;
}
.containerAbsoluteOrigen{
    position: absolute;
    top: -2px;
    left: -2px;
    display: flex;
    background: #9597a7;
    padding-right: 10px;
    border-radius: 10px 0px 10px 0px;
    justify-content: center;
    align-items: center;
    width: 15%;
    padding-bottom: 5px;
    padding-top: 3px;
}
.containerAbsoluteOrigen img{
    width: 30px;

}
.ContainerObservaciones p{
    margin: 0px;
    padding: 0px;
    font-size: 14px;
}
.EstatusPeticionCirculo{
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    border-radius: 50%;
}
.ConainerSeleccion{
    width: 6%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-right: 51px;
}
.ConainerSeleccion p{
    padding-right: 10px;
}
/* NUEVO */
 .ContainerNuevo{
    width: 97%;
    margin: auto;
    max-height: 82vh;
 }


.InfoMercancia-Nuevo { 
   // grid-area: 1 / 4 / 3 / 6;
   grid-area: Reco; 
    position: relative;
    background: fff;
    border-radius: 10px;
    box-shadow: 1px 3px 12px rgb(149, 151, 167);
    background: #fff;
}
/* .ContenedorFlexSinmercancia{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: #fff;
} */
/* .ButonSinmercancia{
    background: #151b4e;
    padding-right: 30px;
    padding-left: 30px;
   cursor: pointer;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
} */
.Info-Recolectores { 
    display:none;
    position: relative;
    border-radius: 10px;
    display: flex;
    flex-direction: column;   
}
.tituloMerca,
.ButonSinmercancia{
    color: rgb(21, 31, 86);
    font-weight: bold;
    font-size: 20px;
    border-bottom: solid;
    border-bottom-color:  rgb(21, 31, 86);
    padding-left: 20px;
    height: 35PX;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: center;
    cursor: pointer;
}
.imgmercancia{
    width: 20px;
    padding-right: 12px;
}
.tituloMerca img,
.ButonSinmercancia img{
    width: 100%;
}
.contenedorAcciones{
    display:flex; 
    flex-direction:row; 
    justify-content:space-evenly; 
    width:20%;
}
.contenedorAcciones img{
width: 27px;
}
.contenedorAcciones div{
    display: flex;
    align-items: center;
}
.ContenedorTablaMercancia{
    color:#333333;
    font-weight: bold;
    overflow-y: scroll;
    overflow-x: hidden;
}
.ContenedorTablaMercancia::-webkit-scrollbar {
  width: 8px;
  background-color: transparent; /* Fondo transparente */
}
.ContenedorTablaMercancia::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}
.ContenedorTablaMercancia::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.containerRecolectores{
        height: 96%;
        border-radius: 8px;
    }
    .containerRecolectores h3{
    color: #3BAF29;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 7px;
}
.recolectores{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    width: 80%;
    height: 45px;
    margin: auto;
    justify-content: center;
    margin-bottom: 5px;
}
.nombreRecolector{
    width: 70%;
    color: #333333;
    font-weight: bold;
}
.imgrecolector{
    width: 35px;
    margin-right: 10px;
    display: flex;
}
.imgrecolector img{
    width: 100%;
}
.asignar img{
cursor: pointer;
width: 45px;
}
.cotainerRecolector{
    max-height: 50%;
    overflow-y: auto;
}
.container-InfoCliente-Nuevo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ContainerTitle-InfoCliente-Nuevo{
    background: ${(props) => props.colorAzul};
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    color: ${(props) => props.Blanco};
    margin-top: 7px;
}
.ContainerTitle-InfoCliente-Nuevo h3{
   margin: 10px;
   font-size: 18px;
}
.container-Origen-InfoCliente-Nuevo{
    width: 94%;
    height: 90%;
    margin: auto;
    font-weight: bold;
    color: #333333;
}
.imgOrigen{
        display: flex;
        flex-direction: row;
        justify-content:space-around;
        width: 60%;
        margin: auto;
    }
/*tipo peticion*/
.imgTipoPeticion{
    margin: auto;
        margin-bottom: 0px;
        height: 80px;
        width: 85px !important;
        padding: 5px;
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 3px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        border-radius: 20px;
        border: solid #666770 1px;
        margin-left: 14%;
        background: #cecece;
        /* padding-top: 15px;
    padding-bottom: 15px; */
    
    }
   /* .imgTipoPeticion:hover{
    background: #3BAF29;
  }*/
  .imgTipoPeticion img{
        width: 65%;
        cursor: pointer;
        margin: auto;
        display: block;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    .activeTipoPeticion{
    background: #3BAF29;
  }
  .imgTipoPeticion p{
    font-size: 12px;
    text-align: center;
  }
    .containerimgorigen{
        margin-bottom: 0px;
        height: 60px;
        width: 128px;
        padding: 5px;
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 3px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        padding-top: 15px;
    padding-bottom: 15px;
    }
    .containerimgorigen:hover{
    background: #3BAF29;
  }
  .active{
    background: rgb(180, 219, 176) !important;
  }
  .ContainerInfoClienteInputs,
  .Container-BuscadorCliente-InfoCliente-Nuevo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .containerimgorigen img{
        width: 70%;
        cursor: pointer;
        margin: auto;
    }

    .bodynuevo{
        display: flex;
        flex-direction: column;
        margin: auto;
        padding-top: 15px;
        padding-bottom: 10px;
    }
    .InfoCliente-Nuevo{
        overflow-x: hidden;
        overflow-y: auto;
        background: #fff;
        border-radius: 10px;
        box-shadow: 1px 3px 12px rgb(149, 151, 167);
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        /* margin-left: 10px; */

  /*Firefox*/
  scrollbar-color: #C5C5C9 transparent;
  scrollbar-width: thin;
  scrollbar-gutter: thin;
    }
  

@keyframes resplandorAnimation {
  0%,100%{
    box-shadow: 0px 0px 10px #ffb531;
  }
  50%{
  box-shadow: 0px 0px 0px #ffb531;
  
  }
}
//Paginador
.contenedorPaginador{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-bottom:10px; 
    margin-top: 10px;
}
.paginador{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin-top: 10px;
}
.num{
    background: #fff;
    height: 33px;
    border: solid #6E7687 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    border-radius: 10px;
    padding-top: 2px;
    padding: 1px 10px;
}
.flecha{
    background: #fff;
    height: 35px;
    width: 35px;
    border: solid #6E7687 1px;
}
//Paginador
.contenedorPaginador{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-bottom:10px; 
    margin-top: 10px;
}
.paginador{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin-top: 10px;
}
.num{
    background: #fff;
    height: 33px;
    border: solid #6E7687 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    border-radius: 10px;
    padding-top: 2px;
    padding: 1px 10px;
}
.flecha{
    background: #fff;
    height: 35px;
    width: 35px;
    border: solid #6E7687 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: 10px;
}
.flecha img{
    height: 20px;
    width: 20px;
}
    /* display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: 10px; */

.flecha img{
    height: 20px;
    width: 20px;
}
/* Nuevo cliente */
.CuerpoNvoCte{
    width: 94%;
    margin: auto;
    margin-left: 8%;
    }
   
    .EncabezadoNvoCte{
    background: rgb(21, 27, 78);
    width: 100%;
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    font-size: 21px;
    height: 41px;
    border-radius: 10px 10px 0px 0px;
    }
    .EncabezadoNvoCte{
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-weight: bold;
    }
    .InfoCteNvoInputs{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .SeparadorNvoCte{
    border-bottom: 2px solid #80808096;
    color: #80808096;
    font-size: 20px;
    font-weight: 700;
    margin: 2% 1%;
    text-align: center;
    width: 98%;
    }
    .ContenedorGeneral p{
    font-weight: bold;
    color: #333333;
    }
    .InfoCteNvoInputs select{
    height: 37px;
    max-height: 37px;
    background: white;
    border: 1px solid rgb(102, 103, 112);
    flex: 1 1 0%;
    border-radius: 7px;
    /* position: relative; */
    }
    /* .regimenfiscalSelect{
        display: flex;
        align-items: center;
        align-content: center;
        flex-direction: column;
    } */
    .regimenfiscalSelect p{
    margin-bottom: 0px;
    }
    .ContenedorGeneral{
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 29px 8px; 
    grid-template-areas: 
    "Mapa NUEVO"; 
    width: 94%;
    margin: auto;
}
    .ContenedorMapa{
    grid-area: Mapa;
    padding-top: 20px;
    }
}
/* @media only screen and (max-width: 1366px) {
 
} */

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) { 
    .ContainerDrag{
        display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 10px; /* Espacio entre los elementos */
  max-height: 100vh;
  width: 90%;
    margin: auto;
    }
   
 }

// /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .ContainerTitle-InfoCliente-Nuevo h3{
        margin: 5px;
        font-size: 22px;
    }
    .containerimgorigen img{
        width: 50%;
        cursor: pointer;
        margin: auto;
    }
    .ContainerDrag{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);
        gap: 10px; /* Espacio entre los elementos */
        max-height: 75vh;
        width: 97%;
        margin: auto;
    }
 }

// /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .bodynuevo{
        display: grid; 
        grid-template-columns: 1fr 1.3fr 0.7fr 1fr; 
        grid-template-rows: 1.6fr 1.3fr 0.1fr;
        gap: 46px 40px; 
        /* max-height: 90vh; */
        gap: 15px 25px;
        padding-bottom: 20px;
        margin-top: 10px;
        padding-top: 5px;
        padding-left: 10px;
        padding-right: 10px;
        grid-template-areas: 
            "CLIENTE CLIENTE Reco Reco"
            "CLIENTE CLIENTE Reco Reco"
            "CLIENTE CLIENTE Reco Reco"
        ;
    }
    .InfoCliente-Nuevo { 
        grid-area: CLIENTE;
        margin-bottom: 37px;
        height: 100%;
    }
   

    /* .containerimgorigen {
        margin-bottom: 0px;
        height: 60px;
        width: 128px;
        padding: 5px;
        cursor: pointer;
    } */

    .container-Origen-InfoCliente-Nuevo p{
        margin: 5px
    }
    .ContainerPaginaPeticiones{
        flex: 1;
        width: 95%;
        margin: auto !important;
        height: 61vh;
    }
    .ConainerSeleccion{
    margin: auto;
    width: 45px;
}
.btnCambioVista{
    margin-right: 0px;
}
 }

// /* Extra large devices (large laptops and desktops, 1366px and up) */
 @media only screen and (min-width: 1366px) {
 
 }
 @media only screen and (min-width: 1370px) {
    .containerimgorigen img{
        width: 85%;
        cursor: pointer;
        margin: auto;
    }
    .imgOrigen {
        width: 80%;
        margin: auto;
    }
    .containerimgorigen {
    margin-bottom: 30px;
    height: 100px;
    width: 100px;
    padding: 5px;
}
.container-Origen-InfoCliente-Nuevo p{
        margin: 10px
    }
    .ContainerPaginaPeticiones{
        height: 70vh;
    }
.contenedorPaginador{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    /* margin-top: 55px;
    padding-bottom:10px;  */
}
.imgTipoPeticion{
        margin-left: 25%
    }
}
`
const InputLayout = styled.section`
   display: flex;
   flex: ${(props) => props.FLEX};
   margin-right: 15px;
 @media only screen and (min-width: 300px) {
 .containerinputbuscador{
    display: flex;
    flex-direction: row;
 }
 .ContainerActionSearch{
    background: #3A9338;
    padding: 3px 11px 4px 6px;
    height: 29px;
    border-radius: 0px 10px 10px 0px;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    cursor: pointer;
    /* margin-top: 4px; */
 }
 .ContainerInput-Nombre p{
    margin-bottom: 0px;
    color: #333333;
    font-weight: bold;
 }
 .InputPrincipal{
    display: flex;
 }
 .InputPrincipal input{
    border: 1px solid #666770;
    border-radius: 7px 0px 0px 7px;
    height: 32px;
    display: flex;
    flex: 1;
    color:#333333;
    width: 100%;
 }
 .listcpdata{
    list-style: none;
    padding-left: 5px;
    margin-top: 3px;
}
.listcpdata > li{
    color: #666770;;
    cursor: pointer;
    font-size: 14px;
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom:8px;
    font-weight: 600;
}
.listcpdata li:hover{
    color: #151B4E;
}
.Select-Filtro{
    height: 37px;
    max-height: 37px;
    background: white;
    border: 1px solid rgb(102, 103, 112);
}

.ContainerInput{     
    flex: 1; 
}
.react-datepicker-wrapper {
    display: flex;
    flex: 1;
}
.react-datepicker__input-container{
    display: flex;
}
}
@media only screen and (max-width: 1366px) {
 
}

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {

 }

// /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
 
 }

// /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

 }

// /* Extra large devices (large laptops and desktops, 1366px and up) */
 @media only screen and (min-width: 1366px) {
  
 }
 @media only screen and (min-width: 1370px) {

}
`
const ModalLayout = styled.section`
 @media only screen and (min-width: 300px) {
    .customModal{
        width: 100%;
        margin: auto;
    }
    .generalInfoShow{
    background: #c3c3c3;
    border-radius: 6px;
    padding: 10px;
    /* width: 20%; */
    /* display: flex;
    flex-direction: row; */
    margin-top: 20px;
    
}
.generalInfoShow p{
    color: #535353;
    font-weight: bold;
    margin: 0;
    /* text-align: center; */
    margin-left: 10px;
    width: 100%;
}
    .Header-Modal{
        background: ${(props) => props.colorHeader};
        display:flex;
        align-items: center;
        border-radius: 10px;
        padding-top:5px;
        padding-bottom: 8px;
    }
    .titulo-modal{
        flex: 3;
        text-align: center;
        font-weight: bold;
        font-size: 22px;
    }
    .titulo-modal h3{
        color: ${(props) => props.Blanco};
        margin: 0px;
        padding: 0px;
    }
    .cerrarmodal{
        cursor: pointer;
    }
    .cerrarmodal p{
        color: ${(props) => props.Blanco};
        margin: 0px;
        padding: 0px;
        font-weight: bold;
        font-size: 22px;
        padding-right: 22px;
    }
    .container-Origen-MODALPETICIONES{
       
    }
    .imgOrigen{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .containerimgorigen{
        width: 150px;
        margin: 40px;
    }
    // MODAL DATOS ONSITE
    .HeaderPaginatorNew{
        display: flex;
        flex-direction: row;
        width: 92%;
        margin: auto;
    }

.btnSeleccionartodo button{
    padding:11px 24px 11px 24px; 
    border-radius:7px; 
    color:#fff; 
    font-weight:bold; 
    background:#444971; 
    cursor:pointer;
    border: none;
    filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.35));
    font-size: 15px;
}
.btnSeleccionartodo button:hover{
    background: #151B4E;
}
.btnAceptar{
    padding-top: 7px;
    display: flex;
    justify-content: center;
    position: sticky;
}
.btnAceptar button {
    background-color: #3BAF29;
    color: #fff;
    border-radius: 7px;
    height: 36px;
    width: 130px;
    border: none;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35));
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    margin: auto;
}
.btnAceptar button:hover {
    background-color: #2E901F;
}
.ContainerCheckAddressButton{
        display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 3px;
    }
    .containerDatosResumen h4{
        margin-bottom: 5px;
        color: #151B4E;
    }
    .containerDatosResumen p{
        margin-top: 0px;
        color: #333333;
    }
    .ContainerCheckAddress p{
        text-align: center;
    color: rgb(102, 103, 112);
    font-weight: 500;
    margin-bottom: 10px;
    }
    .Boton_Aceptar button{
        background-color: #3BAF29;
    color: rgb(255, 255, 255);
    width: 130px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid gray;
    border-radius: 8px;
    font-weight: bold;
    margin-right: 20px;
    filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 4px 4px);
    cursor: pointer;
    }
    .Boton_Aceptar button:hover {
    background-color: #2E901F;
}
    .Boton_NuevaPeticion button{
    border-radius: 7px;
    padding-top: 4px;
    border: none;
    filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 4px 4px);
    cursor: pointer;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid gray;
    border-radius: 8px;
    font-weight: bold;
    margin-left: 20px;
}
.Boton_NuevaPeticion button:hover{
    color: rgb(255, 255, 255);
    background: rgb(21, 27, 78);
}
.Contenido-Grid-NuevadireccionRecoleccion{
    display: grid; 
    grid-auto-columns: 1fr; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 29px 8px; 
  grid-template-areas: 
    "Mapa NUEVO"; 
}
.MapaNueva{
    grid-area: Mapa;
    padding-top: 20px;
}
.Nuevadireccion{
    grid-area: NUEVO;
}
.inputsContenedor{
    max-height: 65vh;
  overflow: auto;
  /*Firefox*/
  scrollbar-color: #C5C5C9 transparent;
  scrollbar-width: thin;
  scrollbar-gutter: thin;
}
.inputsContenedor p{
    font-weight: bold;
    color: #333333;
}
.CambioCiudad{
    display:flex;
   
    width: 100%;
    justify-content: center;
    align-items: center
}
    .ContainerCiudadesCompara{
        display: flex;
        justify-content: space-between;
    }
    .ContenedorbottonesModal{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .Contenedorbotones{
        display: flex;
        justify-content: space-around;
    }
    .BotonSucces{
        background: #2E901F;
        border: 1px solid gray;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        padding-right: 25px;
        padding-left: 25px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-radius: 6px;
    }
    .ContainerModalDetallesPeticion{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        position: relative;
        align-items: center;
        text-align: center;
    }
    .ContainerOrigenDetallesPeticion{
        display: flex;
        align-items: center;
        width: 46px;

    }
    .ContainerOrigenDetallesPeticion img{
        width: 100%;
        display: block;
        margin: auto;
    }
    .itemSoliPet h3{
        color: #151B4E;
        font-size: 18px;
    }
    .itemSoliPet p{
        font-weight: bold;
        color:#333333;
    }
    .ContainerModalDetallesPeticion div p {
        margin-bottom: 10px;
    }
    .TimlineDetallesPetiones{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .TimlineDetallesPetiones li {
        margin-right: 30px;
    }
    .SeparadorHistorialDetallesPeticion{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .SeparadorHistorialDetallesPeticion h4{
        margin-bottom: 5px;
        font-size: 18px;
        color: #151B4E;
        font-weight: bold;
    }
    .ContainerEditarPEticionDetalles{
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .BotonEditerPeticionEstatusDetalles{
        background: #FFB531;
        margin-right: 30px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 7px;
        padding-bottom: 5px;
        padding-top: 5px;
        cursor: pointer;
    }
    .BotonCancelarPeticionEstatusDetalles{
        background: red;
        margin-left: 0px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 7px;
        padding-bottom: 5px;
        padding-top: 5px;
        cursor: pointer;
    }
    // BOTON
    .BotonAceptar{
        background: #3BAF29;
        margin-right: 30px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 7px;
        padding-bottom: 5px;
        padding-top: 5px;
        cursor: pointer;
        border: 1px solid grey;
        color: #fff;
        font-weight: bold;
        font-size: 15px;
    }
    .BotonCancelar{
        background: red;
        margin-left: 0px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 7px;
        padding-bottom: 5px;
        padding-top: 5px;
        cursor: pointer;
        border: 1px solid grey;
        color: #fff
    }

    .BotonCancelarPeticionEstatusDetalles p{
        margin: 0px;
        color: #fff;
        font-weight:bold;
    }
    .BotonEditerPeticionEstatusDetalles p {
        margin: 0px;
        color: #fff;
        font-weight:bold;
    }
  // MODAL MERCANCIA
  .containerimgMercanciaPeticion{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
    margin: auto;
    margin-top: 20px;
  }
  
  .containerimgMercanciaPeticion img{
    width: 100%;
  }
  .ContenedorImagen{
    display: flex;
    justify-content: center;
    width: 5vw;
    height: 11vh;
    padding: 20px;
    border-radius: 10px;
  }
  .ContenedorImagenActivo{
    display: flex;
    justify-content: center;
    width: 5vw;
    height: 11vh;
    padding: 20px;
    background: #3BAF29;
    border-radius: 10px;
  }
  .ContenedorImagen:hover{
    display: flex;
    justify-content: center;
    width: 5vw;
    height: 11vh;
    padding: 20px;
    background: #3BAF29;
    border-radius: 10px;
    cursor: pointer;
  }
  .containerInfoInputs{
    display: flex;
    width: 75%;
    margin: auto;
  }
}
@media only screen and (max-width: 1366px) {
 
}

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {

 }

// /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
 
 }

// /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

 }

// /* Extra large devices (large laptops and desktops, 1366px and up) */
 @media only screen and (min-width: 1366px) {
  
 }
 @media only screen and (min-width: 1370px) {

}
`
const TablaLayout = styled.section`
width: 100% !important;
margin:auto;
margin-top: 1px;
/* margin-right: 6px; */
 @media only screen and (min-width: 300px) {
    .botonesFiltro{
    width: 100%;
    display: flex;
    justify-content: end;
  }
    .TablaSinpaginador{
        width: 100%;
        margin: auto;
    }
    .Cabezera-TablaSinPaginador{
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 7px;
    }
    .cuerpoTabla{
        max-height: 69vh;
        overflow-y: auto;
        /*Firefox*/
  scrollbar-color: #C5C5C9 transparent;
  scrollbar-width: thin;
  scrollbar-gutter: thin;
    }
    .Cuerpo-TablaSinPaginador{
        display: flex;
        flex-direction: row;
        align-items: center;
        /* position: relative; */
        font-size: 12px;
        margin: 5px auto auto;
        color: #333333;
        font-weight: 500;
        background: rgb(255, 255, 255);
    }
    .ItemHeader-TablaSinPaginador{
        text-align:center;
        font-weight: bold;
        color: ${(props) => props.Azul};
        /* margin-right: 10px; */
        height: 5vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-width: 73px;

    }
    .ItemCuerpo-TablaSinpaginador{
        text-align: center;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: center;
        min-width: 73px;
    }
    .campoFecha{
        display: none;
    }
    .Cuerpo-TablaSinPaginador:hover{
        background: #D3D9F9;
        cursor: pointer;
    }
   
    // TABLA PAGINADOR
    .ContenedorTablaFiltrosPaginador{
        display: flex; 
        flex-direction: column-reverse;
        width: 96%;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin: auto;
        margin-left: 19px;
        background: #fff;
        height: 88px;
        border-radius: 5px;
        box-shadow: rgb(106, 106, 106) -1px 2px 14px -3px;
    }
    .ContenedorTablaFiltrosPaginadorTablero{
        display: flex; 
        flex-direction: column-reverse;
        width: 96%;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin: auto;
        margin-left: 19px;
        height: 88px;
    }
    .iconnuevo-peticion{
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
        margin-top: 20px;
        width: 42px;
    }
    .containerFiltrosPaginador{
       position: relative;
        width: 100%;
        margin: auto;
        margin-top: -5px;
        padding-left: 12px;
    }
    .CamposFiltrosPaginaror{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .CamposFiltrosPaginaror-Izquierdo{
        display: flex;
        flex-direction: column;
    }
    
    .Cabezera-TablaPaginador{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
        font-weight: bold;
        width: 100%;
        overflow-x: hidden;
        font-size: 12px;
        background: rgb(47, 55, 104);
        border-radius: 4px;
    }
    .Cuerpoprincipal{
        width: 100%;
        max-height: 45vh;
        overflow-y: scroll;
        padding-right: 0px;
        /*Firefox*/
  scrollbar-color: #C5C5C9 transparent;
  scrollbar-width: thin;
  scrollbar-gutter: thin;
    }
    .ItemCuerpo-Tablapaginador{
        text-align: center;
    }
    .contenedororigenPeticionesTabla{
        display: flex;
        flex-direction: row;
        width: 50px;
        height: 30px;
        align-items: center;
    }
    .ContainerActions{
    position: absolute;
    right: 3px;
    top: 20px;
    width: 35px !important;
    height: 35px;
    border-radius: 100%;
    margin-right: 4px;
}
.ContainerActions span{
    position: absolute;
    /* background: #9597A7; */
    border-radius: 50%;
    width: 5px;
    height: 5px;
    right: 15px;
}
.active{
position: absolute;
right: 3px;
top: 20px;
width: 35px !important;
height: 35px;
background-color: #D9D9D9;
border-radius: 100%;
/* opacity: 40%; */
cursor: pointer;
}
.ContainerActions span:nth-child(1){
    bottom: 26px;
}
.ContainerActions span:nth-child(2){
    bottom: 16px;
}
.ContainerActions span:nth-child(3){
    bottom: 6px;
}
.ContainerSubAction{
    display: flex;
    flex-direction: column !important;
    position: absolute !important;
    right: 25px;
    background: #DBDDED !important;
    border-radius: 8px;
    z-index: 2;
    box-shadow: -1px 7px 17px rgb(0 0 0 / 35%);    
    border: solid #D4D3D3 1px;
    color: #666770;
    font-weight: bold;
    cursor: pointer;
    width: 225px;
}
.ItemSubmenu{
    display: flex;
    justify-content: space-around;
    height: 49px;
}
.ItemSubmenu img{
    padding-right: 10px;
}
.ItemSubmenu p{
    text-align: left;
    max-width: 80%;
}
}
@media only screen and (max-width: 1366px) {

}

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .Cuerpo-TablaSinPaginador{
        font-size: 14px;
        width: 100%;
        font-weight: 600;
       
    }
    .Cabezera-TablaPaginador{
       font-size: 14px;
        width: 100%;
    }
    .CamposFiltrosPaginaror-Izquierdo{
        flex-direction: row;
    }
 }
// /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
 
 }

// /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .ContenedorTablaFiltrosPaginador{
    flex-direction: row;
    width: 99.5%;
    margin: auto;
  }
  .ContenedorTablaFiltrosPaginadorTablero{
      flex-direction: row;
      width: 96%;
      margin: auto;
  }
  .botonesFiltro{
    flex:1;
    display: flex;
    justify-content: end;
  }
  .CamposFiltrosPaginaror{
      width: 100%;
      flex-direction: row;
      
  }
   .iconnuevo-peticion{
        position: relative;
        cursor: pointer;
        margin-top: 0px;
        display: flex;
        justify-content: end;
        width: 42px;
        right: 9px;
    }
    .campoFecha{
        display: block;
    }
    .containerFiltrosPaginador{
        flex:8;
    }
 }

// /* Extra large devices (large laptops and desktops, 1366px and up) */
 @media only screen and (min-width: 1366px) {
  
 }
 @media only screen and (min-width: 1370px) {
    .Cuerpoprincipal{
                max-height: 50vh;
    }
}
`
const ReportesLayout = styled.section`
height: 100%;

/* display: flex;
justify-content: center;
flex-direction: column; */

.ContainerReporteGanancias{

}
 @media only screen and (min-width: 300px) {
    .tituloRep{
        background: #151B4E;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 21px;
        height: 11%;
        border-radius: 15px 15px 0px 0px;
    }
    .tituloRep h1{
        margin: 0px;
    }
    .ContainerReportes{
        width: 85%;
        height: 70%;
        margin: auto;
        background-color: #fff;
        border-radius: 15px;
        filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 4px 4px);
        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    }
    .ContenedorTipo{
        width: 80%;
    }
    .ContenedorCuerpo{
        display: flex;
        justify-content:space-around;
        color: #333333;
        font-weight: bold;
        width: 80%;
        margin: auto;
    }
    .fechas{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80%;
        padding-top: 12px;
    }
    .ContenedorFecha{
        width: 100%;
    }
    .contenedorTipoRep{
        display: flex;
        flex-direction: column;
        height: 35%;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid rgb(215, 216, 218) 2px;
        padding-top: 20px;
        padding-bottom: 37px;
  }
  .contenedorFiltro{
        display: flex;
        flex-direction: column;
        height: 17%;
        justify-content: center;
        padding-top: 10px;
    }
    .contenedorInputReportes{
        width: 95%;
        height: 89%;
    }
    /* REPORTE DE GANANCIAS */
        .filtrosGanancia{
            
        }
        .HeaderReporteTitle{
        width: 100%;
        background: #151B4E;
        color: #fff;
        text-align:center;
        padding-top:2px;
        padding-bottom: 2px;
        border-radius: 10px 10px 0px 0px;
    }
    .filtrosGanancia{
        display: flex;
        justify-content: space-between;
        padding-right: 15px;
        padding-left: 15px;
    }
    .continaerFechaFiltro{
        display: flex;
        flex-direction: row;
    }
    .ContainerFechaFilto{
        margin-right: 10px;
    }
    .Containerfiltrog{
        cursor: pointer;
    }
    .CuerpoWidgetsGanancias{
        width: 100%;
    }
    .ContainerGanancias{
        display:flex;
        justify-content: space-between;
        flex-wrap: wrap
    }
    .ContainerCurepoWidget{
        width: 100%;
    }
    .ContainerTituloWidget{
       
        border-radius: 10px 10px 0px 0px
    }
    .ContainerTituloWidget{
        color: #fff;
        text-align: center;
        padding-top:10px;
        padding-bottom: 10px;
    }
    .ContainerTituloWidget p{
        margin: 0px;
    }
    .ContenedorDataWidget{
        width: 100%;
        height: 60px;
        background: #D9D9D9;
        border-radius: 0px 0px 10px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ContenedorDataWidget p{
        margin: 0px;
        font-weight: bold;
        font-size: 18px;
    }
    // 
    .ContinerGananciWid{
        flex: 1;
        margin-right: 20px;
        margin-left: 20px;
    }
    .ContinerGananciWidSub{
        flex: 1;
        margin-right: 20px;
        margin-top: 20px;
        margin-left: 20px;
    }
    .TablaDeskGanancia{
        display: none;
    }
    .TablaMovilGanancia{
        display: block;
    }
    }
@media only screen and (max-width: 1366px) {

}
 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .ContainerReportes{
    margin-top: 0px;
    margin: auto;
    }
    .fechas{
        flex-direction: row;
    }
    .ContenedorFecha{
        width: 50%;
    }
    .contenedorFiltro{
        flex-direction: row;
    }
    .ContenedorCuerpo{
        width: 40%;
        margin: 0px;
    }
 }

// /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .TablaMovilGanancia{
        display: none !important;
    }
 }

// /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .CuerpoWidgetsGanancias{
        width: 85%;
        margin-left: 7%;
    }
    .ContinerGananciWid{
        margin-right: 50px;
    }
    .ContinerGananciWidSub{
        margin-right: 50px;
    }
    .TablaDeskGanancia{
        display: block;
    }
    .ContenedorTipo{
        width: 40%;
    }
    .fechas{
        justify-content: end;
    }
    .ContenedorFecha{
        width: 30%;
    }
    .contenedorTipoRep{
        flex-direction: row;
  }
  .contenedorFiltro{
    justify-content: start;
    height: 35%;
    padding-top: 0px;
  }
  .contenedorTipoRep{
    padding-bottom: 0px;
    padding-top: 0px;
  }
  .ContenedorCuerpo{
    width: 28%;
  }
 }

// /* Extra large devices (large laptops and desktops, 1366px and up) */
 @media only screen and (min-width: 1367px) {
  .ContainerReportes{
    width: 59%;
    height: 45%;
  }
 }
 @media only screen and (min-width: 1370px) {

}
`
const CalendarioLayout = styled.section`
    .TitleCalendario{
        margin-left: 2%;
        font-weight: bold;
        font-size: 20px;
    }
    .ContainerSemanas{
       
       display: grid; 
       grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
       grid-template-rows: 1fr 1fr 1fr; 
       max-height: 250px;
       padding-left: 10px;
       padding-right: 10px;
   }
   .ContainerCardsCalendario{
       width: 80%;
       margin-bottom: 30px;
       border-radius: 10px 10px 10px 10px;
       cursor: pointer
   }
   .ContainerCalendarioCustom{
       display: grid; 
       grid-template-columns: 1fr 1fr 1fr; 
       grid-template-rows: 1fr 1fr 1fr; 
       padding-left: 10%;
   }
   .ContainerCalendarioCardsTitle{
       background-color: #5b5f83;
       /* border-radius: 10px 10px 0px 0px; */
       padding-top: 5px;
       padding-bottom: 5px;
       border-radius: 17px;
       width: 75%;
       margin: auto;
   }
   .ContainerCalendarioCardsTitle p {
       text-align: center;
       font-weight: bold;
       color: #fff;
       margin:0px;
       text-transform: uppercase;
   }
   .ContainerDiaySemana{
       text-align: center;
   }
   .ContainerDia{
    font-size: 15px;
       /* max-width: 32px; */
       width: 32px;
       height: 30px;
       display: flex;
       justify-content: center;
       align-items: center;
       margin: auto;
       margin-top: 0px;
       margin-bottom: 6px;
       border-radius: 7px;
   }
   .ContainerFechasCalendario{
       display: flex;
       flex-direction: row;
       justify-content: space-between
   }
   .ContainerBodyFechasCalendario{
       width: 75%;
   }
   .ContainerFechasCalendarioP{
       display: flex;
       width: 50%;
       justify-content:center;
   }
   .CalendarioiconNuevo{
       /* flex: 1; */
       display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-top: 30px;
   }
   .containerDiasTitle{
    width: 50%;
       display:flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
   }
   .TittelDiasCalendario > p{
       font-weight: bold;
       font-size: 19px;
   }
`
const LoaderLayout = styled.section`
 @media only screen and (min-width: 300px) {
    .loaderImg {
    position: relative;
    width: 100px;
    margin: 80px auto;
    transform: rotate(180deg);
}
.loader
.loader img{
    height: 85%;
    width: 85%;
}
.duo {
    height: 55px;
    width: 110px;
    position: absolute;
}
.duo, .dot {
    animation-duration: 0.7s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
.duo1 {
    right: 27px;
}
.duo2 {
    left: 20px;
}
.dot {
    width: 40px;
    height: 40px;
    position: absolute;
}
.dot-a {
    left: 0px;
}
.dot-b {
    right: 0px;
}
@keyframes spin {
    0% { transform: rotate(0deg) }
    50% { transform: rotate(180deg) }
    100% { transform: rotate(180deg) }
}
@keyframes onOff {
    0% { opacity: 0; }
    49% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 1; }
}
.duo1 {
    animation-name: spin;
}
.duo2 {
    animation-name: spin;
    animation-direction: reverse;
}
.duo2 .dot-b {
    animation-name: onOff;
}
.duo1 .dot-a {
    opacity: 0;
    animation-name: onOff;
    animation-direction: reverse;
}
.loaderTexto{
    display: flex;
    justify-content: center;
    color: #3BAF29;
    font-weight: bold;
    font-size: 22px;
    padding-top: 8px;
    letter-spacing: 1px;
}
}
@media only screen and (max-width: 1366px) {

}

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
 }

// /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
 
 }

// /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

 }

// /* Extra large devices (large laptops and desktops, 1366px and up) */
 @media only screen and (min-width: 1366px) {
  
 }
 @media only screen and (min-width: 1370px) {

}
`
//  RESPONSIVE
// @media only screen and (min-width: 300px) {

// }

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {

// }

// /* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {

// }

// /* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {

// }

// /* Extra large devices (large laptops and desktops, 1366px and up) */
// @media only screen and (min-width: 1366px) {

// }
const DireccionesLayout = styled.section`
 @media only screen and (min-width: 300px) {
.CampoTabla{
    width: 95%;
    margin: auto;
}
//Paginador
.contenedorPaginador{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-bottom:10px; 
    margin-top: 10px;
}
.paginador{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin-top: 10px;
}
.num{
    background: #fff;
    height: 33px;
    border: solid #6E7687 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    border-radius: 10px;
    padding-top: 2px;
    padding: 1px 10px;
}
.flecha{
    background: #fff;
    height: 35px;
    width: 35px;
    border: solid #6E7687 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: 10px;
}
.flecha img{
    height: 20px;
    width: 20px;
}
}
// /* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

// /* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

// /* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

// /* Extra large devices (large laptops and desktops, 1366px and up) */
@media only screen and (min-width: 1366px) {
}
`
const NuevoClienteLayout = styled.section`
 @media only screen and (min-width: 300px) {
    .CuerpoNvoCte{
    width: 94%;
    margin: auto;
    margin-left: 8%;
    }
   
    .EncabezadoNvoCte{
    background: rgb(21, 27, 78);
    width: 100%;
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    font-size: 21px;
    height: 41px;
    border-radius: 10px 10px 0px 0px;
    }
    .EncabezadoNvoCte{
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-weight: bold;
    }
    .InfoCteNvoInputs{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .SeparadorNvoCte{
    border-bottom: 2px solid #80808096;
    color: #80808096;
    font-size: 20px;
    font-weight: 700;
    margin: 2% 1%;
    text-align: center;
    width: 98%;
    }
    .ContenedorGeneral p{
    font-weight: bold;
    color: #333333;
    }
    .InfoCteNvoInputs select{
    height: 37px;
    max-height: 37px;
    background: white;
    border: 1px solid rgb(102, 103, 112);
    flex: 1 1 0%;
    border-radius: 7px;
    /* position: relative; */
    }
    /* .regimenfiscalSelect{
        display: flex;
        align-items: center;
        align-content: center;
        flex-direction: column;
    } */
    .regimenfiscalSelect p{
    margin-bottom: 0px;
    }
    .ContenedorGeneral{
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 29px 8px; 
    grid-template-areas: 
    "Mapa NUEVO"; 
    width: 94%;
    margin: auto;
}
    .ContenedorMapa{
    grid-area: Mapa;
    padding-top: 20px;
    }
    .InputPais{
    display: flex;
    flex-direction: row;
    border: 1px solid #666770;
    border-radius: 5px 5px 5px 5px;
    height: 32px;
    display: flex;
    flex: 1;
    color:#333333;
    width: 100%;
    }
    .bandera{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .nombrePais{
    width: 80%;
    height: 32px;
    display: flex;
    -moz-box-pack: center;
    -moz-box-align: center;
    align-items: center;
    }
    .bandera img{
        width: 60%;
    }
}
// /* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

// /* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

// /* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

// /* Extra large devices (large laptops and desktops, 1366px and up) */
@media only screen and (min-width: 1366px) {
}
`

export {
    // ROOT LAYOUTS
    Layoutcontainer,
    LoginLayout,
    // LAYOUT SIDEBAR, NAVBAR
    LayoutMonitor,
    SidebarLayout,
    HeaderLayout,
    // PAGINAS
    DashboardLayout,
    WidgetDashboarLayout,
    MapaMonitorNuevoLayout,
    MapaMonitorLayout,
    PeticionesLayout,
    ReportesLayout,
    CalendarioLayout,
    NuevoClienteLayout,

    // UTILIDADES
    InputLayout,
    ModalLayout,
    TablaLayout,
    LoaderLayout,
    DireccionesLayout
}